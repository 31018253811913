// drawer-btn
.drawer-btn {
  display: none;

  @include media(tb) {
    z-index: 101;
    position: relative;
    width: 28px;
    height: 22px;
    display: block;
    cursor: pointer;
  }

  & > span {
    position: absolute;
    left: 0;
    display: inline-block;
    width: 100%;
    height: 2px;
    background-color: $color-bk;
    transform-origin: 50%;
    transition: 0.4s;

    &:nth-of-type(1) {
      transform: rotate(0) translateY(-12px);

      @include media(sp) {
        transform: rotate(0) translateY(-11px);
      }
    }

    &:nth-of-type(3) {
      transform: rotate(0) translateY(12px);

      @include media(sp) {
        transform: rotate(0) translateY(11px);
      }
    }
  }

  &.active {
    & > span {
      &:nth-of-type(1) {
        transform: translateY(0) rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(0) rotate(45deg);
      }
    }
  }
}

//　drawer-modal
.drawer-modal {
  position: fixed;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $color-wh;
  margin-top: 70px;
  transition: all 0.5s;
  visibility: hidden;
  opacity: 0;
  z-index: 99;

  @include media(sp) {
    margin-top: 60px;
  }

  &.open {
    visibility: visible;
    opacity: 1;
  }

  &__inner {
    width: 100%;
    height: 100%;
  }

  &__list {
    width: 100%;
  }

  &__item {
    position: relative;
    width: 100%;
    border-top: 1px solid #ccc;

    &:last-child {
      border-bottom: 1px solid #ccc;
    }

    & > a {
      display: block;
      padding: 18px 34px;
      font-size: 1.7rem;
      font-weight: 500;
      letter-spacing: track(50);
      color: $color-main;
    }

    &.--airport .text {
      position: relative;
      padding-left: 24px;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 18px;
        height: 18px;
        background-image: url(../images/common/icon/airport_02.svg);
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
  .drawer-modal {
    position: fixed;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;

    &__inner {
      height: 500px !important;
      margin-bottom: 50px;
    }
  }
}
