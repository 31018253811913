.sec {
  @include re-p;
  padding-top: 90px;
  padding-bottom: 100px;

  @include media(pc-s) {
    padding-top: res(90px);
    padding-bottom: res(100px);
  }

  @include media(sp) {
    padding-top: 46px;
    padding-bottom: 50px;
  }

  &__title {
    position: relative;
    display: block;
    width: 100%;
    margin: 0 auto;

    &::before {
      content: '';
      display: block;
      padding-top: percentage(27px / 318px);
    }

    &>img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
