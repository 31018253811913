/* footer
------------------------------------------------ */
.footer {
  background-color: $color-main;
  margin-top: 126px;
  padding: 70px 0 24px;

  @include media(pc-s) {
    margin-top: res(126px);
    padding: res(70px) 0 res(24px);
  }

  @include media(sp) {
    margin-top: 67px;
    padding: 40px 0 80px;
  }

  &.-mt0 {
    margin-top: 0;
  }

  &__inner {
    max-width: 600px;
    margin: 0 auto;
    color: $color-wh;

    @include media(pc-s) {
      max-width: res(600px);
    }

    @include media(sp) {
      padding: 0 35px;
    }
  }

  &__container {
    display: flex;
    margin-left: -40px;

    @include media(pc-s) {
      margin-left: res(-40px);
    }

    @include media(sp) {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
    }
  }

  &__info,
  .fnav {
    width: calc(50% - 40px);
    margin-left: 40px;

    @include media(pc-s) {
      width: calc(50% - #{res(40px)});
      margin-left: res(40px);
    }

    @include media(sp) {
      width: 100%;
      margin-left: 0;
    }
  }

  &__info {
    display: flex;
    flex-direction: column;
    margin-top: -18px;

    @include media(pc-s) {
      margin-top: res(-18px);
    }

    @include media(sp) {
      align-items: center;
      margin-top: 0;
    }
  }

  &__logo {
    & > a {
      position: relative;
      display: block;
      width: 248px;
      z-index: 1;

      @include media(pc-s) {
        width: res(248px);
      }

      @include media(sp) {
        width: 200px;
      }

      &::before {
        content: "";
        display: block;
        padding-top: percentage(96px / 248px);
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:hover {
        opacity: 0.7;

        @include media(tb) {
          opacity: 1;
        }
      }
    }
  }

  .bnrArea {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: -28px;
    padding-top: 30px;
    margin-left: -24px;

    @include media(pc-s) {
      margin-top: res(-28px);
      padding-top: res(30px);
      margin-left: res(-24px);
    }

    @include media(sp) {
      flex-direction: column;
      margin-top: -20px;
      padding-top: 24px;
      margin-left: 0;
    }
  }

  .bnrArea__img {
    width: calc(50% - 24px);
    margin-top: 28px;
    margin-left: 24px;

    @include media(pc-s) {
      width: calc(50% - #{res(24px)});
      margin-top: res(28px);
      margin-left: res(24px);
    }

    @include media(sp) {
      width: 100%;
      margin-top: 20px;
      margin-left: 0;

      img {
        max-width: none;
        width: 100%;
      }
    }

    // &:last-child {
    //   margin-left: 30px;

    //   @include media(pc-s) {
    //     margin-left: res(30px);
    //   }

    //   @include media(sp) {
    //     margin-left: 0;
    //     margin-top: 20px;
    //   }
    // }

    & > a {
      &:hover {
        opacity: 0.7;

        @include media(tb) {
          opacity: 1;
        }
      }
    }
  }

  .bnrArea__txt {
    margin-top: 8px;
    text-align: center;
    font-size: 1.5rem;
    letter-spacing: track(100);
    font-weight: bold;

    @include media(pc-s) {
      margin-top: res(8px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      margin-top: 8px;
      font-size: 1.5rem;
    }
  }

  .fnav {
    &__list {
      display: flex;
      flex-wrap: wrap;
      // justify-content: center;
      margin-left: -24px;

      @include media(pc-s) {
        margin-left: res(-24px);
      }

      @include media(sp) {
        margin-top: 35px;
        margin-left: 22px;
      }
    }

    &__item {
      width: calc(50% - 24px);
      margin-top: 28px;
      margin-left: 24px;

      @include media(pc-s) {
        width: calc(50% - #{res(24px)});
        margin-top: res(28px);
        margin-left: res(24px);
      }

      @include media(sp) {
        width: 50%;
        margin-top: 18px;
        margin-left: 0;
      }

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      & > a {
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: track(100);

        @include media(pc-s) {
          font-size: res(1.2rem);
        }

        @include media(sp) {
          font-size: 1.2rem;
        }

        &:hover {
          opacity: 0.7;

          @include media(tb) {
            opacity: 1;
          }
        }
      }
    }
  }

  // footer-link
  .footer-link {
    margin-top: 70px;

    @include media(pc-s) {
      margin-top: res(70px);
    }

    @include media(sp) {
      margin-top: 40px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -30px;

      @include media(pc-s) {
        margin-left: res(-30px);
      }

      @include media(sp) {
        flex-direction: column;
        align-items: center;
        margin-left: 0;
      }
    }

    &__item {
      width: calc(50% - 30px);
      margin-top: 26px;
      margin-left: 30px;

      @include media(pc-s) {
        width: calc(50% - #{res(30px)});
        margin-top: res(26px);
        margin-left: res(30px);
      }

      @include media(sp) {
        width: 100%;
        margin-top: 12px;
        margin-left: 0;

        &:nth-child(2) {
          margin-top: 12px !important;
        }
      }

      &:first-child,
      &:nth-child(2) {
        margin-top: 0;
      }

      & > a {
        position: relative;
        display: block;
        width: 100%;

        &::before {
          content: "";
          display: block;
          padding-top: percentage(77px / 284px);
        }

        & > img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }

        &:hover {
          opacity: 0.7;

          @include media(tb) {
            opacity: 1;
          }
        }
      }
    }
  }

  .copyright {
    margin-top: 74px;
    font-size: 1rem;
    font-weight: 500;
    letter-spacing: track(30);
    text-align: center;

    @include media(pc-s) {
      margin-top: res(74px);
    }

    @include media(sp) {
      margin-top: 46px;
    }
  }
}
