.l-sec {
  position: relative;

  &__title {
    @include re-m;
    background-image: url(../images/external/bg@2x.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 100%;
    padding: 150px 0 108px;
    font-size: 4rem;
    font-weight: 500;
    letter-spacing: track(300);
    text-align: center;
    color: $color-wh;

    @include media(pc-s) {
      padding: res(150px) 0 res(108px);
      font-size: res(4rem);
    }

    @include media(sp) {
      padding: 88px 0 60px;
      font-size: 2.3rem;
    }

    span {
      position: relative;
      display: block;
      margin-top: 18px;
      padding-bottom: 12px;
      font-size: 1rem;
      line-height: 0;

      @include media(pc-s) {
        margin-top: res(18px);
        padding-bottom: res(12px);
      }

      @include media(sp) {
        padding-bottom: 8px;
      }

      img {
        width: 160px;

        @include media(pc-s) {
          width: res(160px);
        }

        @include media(sp) {
          width: 133px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        background-color: $color-main;
        width: 78px;
        height: 1px;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        @include media(pc-s) {
          width: res(78px);
        }

        @include media(sp) {
          width: 69px;
        }
      }
    }
  }
}

.lower {
  margin-top: 226px;

  @include media(pc-s) {
    margin-top: res(226px);
  }

  @include media(sp) {
    margin-top: 105px;
  }

  &.smartmover {
    margin-top: 170px;
    @include media(pc-s) {
      margin-top: res(170px);
    }

    @include media(sp) {
      margin-top: 80px;
    }
  }

  &__title {
    font-size: 6rem;
    color: $color-main;
    text-align: center;
    letter-spacing: track(300);
    font-weight: 500;

    @include media(pc-s) {
      font-size: res(6rem);
    }

    @include media(sp) {
      font-size: 2.8rem;
      margin-left: 5px;
    }

    span {
      display: block;
      margin: 0 auto;
      font-size: 1.5rem;
      margin-top: 20px;
      letter-spacing: track(200);

      @include media(pc-s) {
        margin-top: res(20px);
        font-size: res(1.5rem);
      }

      @include media(sp) {
        margin-top: 8px;
        font-size: 1rem;
      }
    }
  }
}


.lowerkv {
  position: relative;
  width: 100%;
  height: 480px;
  margin-top: 120px;

  @include media(pc-s) {
    height: res(480px);
    margin-top: res(120px);
  }

  @include media(sp) {
    height: 120px;
    margin-top: 60px;
  }

  &.-service {
    background-image: url(../images/service/kv@2x.jpg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;

    @include media(pc-s) {}

    @include media(sp) {}
  }

  &.-flow {
    background-image: url(../images/flow/kv@2x.jpg);
    background-repeat: no-repeat;
    background-position: 0 0;
    background-size: cover;

    @include media(pc-s) {}

    @include media(sp) {}
  }

  &__title {
    position: absolute;
    top: 38%;
    left: 50%;
    transform: translate(-50%);
    font-size: 6rem;
    font-weight: 500;
    letter-spacing: track(300);
    text-align: center;
    color: $color-wh;
    text-shadow: 1px 2px 10px #231815;

    @include media(pc-s) {
      font-size: res(6rem);
      white-space: nowrap;
    }

    // @include media(tb) {
    //   top: 40%;
    //   font-size: 3.3rem;
    // }

    @include media(sp) {
      top: 32%;
      font-size: 2.8em;
      white-space: nowrap;
    }

    span {
      display: block;
      margin-top: 22px;
      font-size: 1.5rem;
      font-family: $font-en;
      letter-spacing: track(200);
      font-weight: 400;

      @include media(pc-s) {
        margin-top: res(22px);
        font-size: res(1.5rem);
      }

      // @include media(tb) {
      //   margin-top: 12px;
      //   font-size: 1.2rem;
      // }

      @include media(sp) {
        margin-top: 8px;
        font-size: 1rem;
      }
    }
  }
}
