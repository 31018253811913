.smartmover {
  &__inner {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    @include media(pc-s) {
      max-width: res(1000px);
    }
    @include media(sp) {
      padding: 0 15px;
      img {
        max-width: none;
        width: 100%;
      }
    }
  }

  &__text {
    margin: 0 auto;
    margin-top: 60px;
    padding-bottom: 60px;
    border-bottom: 1px solid #000;
    text-align: center;
    @include media(pc-s) {
      margin-top: res(60px);
      padding-bottom: res(60px);
    }
    @include media(sp) {
      margin-top: 30px;
      padding-bottom: 30px;
    }
  }
}

.smartmoverBox {
  display: flex;
  align-items: center;
  max-width: 860px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  @include media(pc-s) {
    margin-top: res(50px);
  }
  @include media(sp) {
    flex-direction: column;
    margin-top: 30px;
  }

  &__text {
    width: 230px;
    height: 230px;
    padding: 50px 32px;
    background-color: #0076c7;
    color: #fff;
    font-size: 2.1rem;
    letter-spacing: track(50);
    line-height: 1.476190476;
    border-radius: 50%;
    text-align: center;
    @include media(pc-s) {
      width: res(230px);
      height: res(230px);
      padding: res(50px) res(32px);
      font-size: res(2.1rem);
    }
    @include media(sp) {
      width: 300px;
      height: 110px;
      padding: 18px 32px;
      border-radius: 5em;
      font-size: 1.8rem;
      line-height: 1.368421053;
    }
    span {
      font-size: 2.8rem;
      font-weight: bold;
      line-height: 1.357142857;
      @include media(pc-s) {
        font-size: res(2.8rem);
      }
      @include media(sp) {
        font-size: 2.2rem;
        line-height: 1.318181818;
      }
    }
  }

  &__textbox {
    margin-left: 50px;
    @include media(pc-s) {
      margin-left: res(50px);
    }
    @include media(sp) {
      margin-left: 0;
    }
  }

  &__txt {
    font-size: 1.8rem;
    letter-spacing: teack(100);
    line-height: 2.222222222;
    @include media(pc-s) {
      font-size: res(1.8rem);
    }
    @include media(sp) {
      margin-top: 24px;
      text-align: center;
      font-size: 1.5rem;
      line-height: 2.066666667;
    }
  }

  &__title {
    margin-top: 22px;
    padding: 0 6px;
    padding-bottom: 4px;
    font-size: 3.7rem;
    font-weight: bold;
    color: #002b54;
    letter-spacing: track(100);
    background: linear-gradient(transparent 60%, #fff36c 0%);
    @include media(pc-s) {
      margin-top: res(22px);
      padding: 0 res(6px);
      padding-bottom: res(4px);
      font-size: res(3.7rem);
    }
    @include media(tb) {
      font-size: 2.6rem;
    }
    @include media(sp) {
      margin-top: 18px;
      font-size: 2rem;
    }
  }
}

.smartmoverTitle {
  position: relative;
  margin-top: 100px;
  padding: 20px 0;
  font-size: 3.3rem;
  letter-spacing: track(100);
  font-weight: bold;
  color: #002b54;
  text-align: center;
  background-color: #f2f2e6;
  @include media(pc-s) {
    margin-top: res(100px);
    padding: res(20px) 0;
    font-size: res(3.3rem);
  }
  @include media(sp) {
    margin-top: 60px;
    font-size: 2.5rem;
    line-height: 1.28;
  }

  &.-mt {
    margin-top: 70px;
    @include media(pc-s) {
      margin-top: res(70px);
    }
    @include media(sp) {
      margin-top: 60px;
    }
  }

  &::before {
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    border-top: 36px solid transparent;
    border-right: 16px solid #fff;
    border-bottom: 36px solid transparent;
    @include media(pc-s) {
      border-top: res(36px) solid transparent;
      border-right: res(16px) solid #fff;
      border-bottom: res(36px) solid transparent;
    }
    @include media(sp) {
      border-top: 45px solid transparent;
      border-right: 21px solid #fff;
      border-bottom: 45px solid transparent;
    }
  }
  &::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    border-left: 16px solid #fff;
    border-top: 36px solid transparent;
    border-bottom: 36px solid transparent;
    @include media(pc-s) {
      border-left: res(16px) solid #fff;
      border-top: res(36px) solid transparent;
      border-bottom: res(36px) solid transparent;
    }
    @include media(sp) {
      border-left: 21px solid #fff;
      border-top: 45px solid transparent;
      border-bottom: 45px solid transparent;
    }
  }

  &.-single {
    &::before {
      @include media(sp) {
        border-top: 32px solid transparent;
        border-right: 20px solid #fff;
        border-bottom: 32px solid transparent;
      }
    }
    &::after {
      @include media(sp) {
        border-left: 20px solid #fff;
        border-top: 32px solid transparent;
        border-bottom: 32px solid transparent;
      }
    }
  }

  &.--use{
    padding-left: 110px;

    @include media(pc-s) {
      padding-left: res(110px);
    }

    @include media(sp) {
      padding-left: 100px;
    }
  }
}

.smartmoverTitle__icon{
  position: absolute;
  width: 92px;
  height: auto;
  margin: 0 auto;
  top: 52%;
  left: -400px;
  right: 0;
  transform: translateY(-50%);

  @include media(pc-s) {
    width: res(92px);
    left: res(-400px);
  }

  @include media(sp) {
    width: 90px;
    left: -170px;
  }
}

.smartmoverUse {
  margin-top: 50px;
  @include media(pc-s) {
    margin-top: res(50px);
  }
  @include media(sp) {
    margin-top: 30px;
  }
}

.smartmoverUseBox {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-top: 34px;
  padding: 40px;
  background-color: #fff46c;
  border-radius: 20px;
  @include media(pc-s) {
    max-width: res(970px);
    margin-top: res(34px);
    padding: res(40px);
  }
  @include media(sp) {
    margin-top: 25px;
    padding: 20px;
  }

  &.-p {
    padding: 20px 40px;
    @include media(pc-s) {
      padding: res(20px) res(40px);
    }
    @include media(sp) {
      padding: 20px 25px;
    }
  }

  &::before {
    position: absolute;
    content: "";
    width: 64px;
    height: 32px;
    bottom: -32px;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../images/smartmover/arrow.svg);
    background-repeat: no-repeat;
    @include media(pc-s) {
      width: res(64px);
      height: res(32px);
      bottom: res(-32px);
    }
    @include media(sp) {
      width: 50px;
      height: 25px;
      bottom: -25px;
    }
  }

  &:last-child {
    &::before {
      content: none;
    }
  }

  &__inner {
    display: flex;
    align-items: center;
    max-width: 890px;
    margin: 0 auto;
    @include media(pc-s) {
      max-width: res(890px);
    }
    @include media(sp) {
      flex-direction: column;
    }
  }
}

.smartmoverUseBoxFlex {
  &.-text {
    max-width: 530px;
    width: 100%;
    margin-left: 52px;
    @include media(pc-s) {
      max-width: res(530px);
      margin-left: res(52px);
    }
    @include media(sp) {
      margin-left: 0;
    }
  }

  &__img {
    &.-mt1 {
      max-width: 291px;
      width: 100%;
      margin-top: 31px;
      margin-left: 16px;
      @include media(pc-s) {
        max-width: res(291px);
        margin-top: res(31px);
        margin-left: res(16px);
      }
      @include media(sp) {
        max-width: 263px;
        width: 100%;
        margin-left: 0;
        margin-top: 0;
        margin: 0 auto;
      }
    }

    &.-mt2,
    &.-mt3 {
      max-width: 320px;
      @include media(pc-s) {
        max-width: res(320px);
      }
      @include media(sp) {
        max-width: 290px;
      }
    }
  }

  &__imgtext {
    max-width: 320px;
    width: 100%;
    margin-top: 14px;
    font-size: 2.4rem;
    letter-spacing: track(100);
    line-height: 1.583333333;
    color: #002b54;
    text-align: center;
    font-weight: bold;
    @include media(pc-s) {
      max-width: res(320px);
      margin-top: res(14px);
      font-size: res(2.4rem);
    }
    @include media(tb) {
      max-width: 320px;
      font-size: 1.8rem;
    }
    @include media(sp) {
      font-size: 2.1rem;
      max-width: none;
      margin-top: 16px;
    }
  }

  &__mini {
    margin-top: 26px;
    font-size: 1.5rem;
    line-height: 1.666666667;
    text-indent: -1em;
    padding-left: 1em;
    color: #002b54;
    letter-spacing: track(-10);
    @include media(pc-s) {
      margin-top: res(26px);
      font-size: res(1.5rem);
    }
    @include media(sp) {
      margin-top: 20px;
      font-size: 1.5rem;
    }

    & + .smartmoverUseBoxFlex__mini {
      margin-top: 0;

      &.-red {
        color: #fc0000;
      }
    }
  }

  &__button {
    position: relative;
    font-size: 2.6rem;
    letter-spacing: track(100);
    border-radius: 5em;
    color: #fff;
    font-weight: bold;
          text-align: center;
    @include media(pc-s) {
      margin-top: res(15px);
      font-size: res(2.6rem);
    }
    @include media(sp) {
      margin-top: 15px;
      font-size: 1.8rem;
    }

    &.-web {
      background-color: #0076c7;
      margin-top: 15px;


      @include media(pc-s) {
        margin-top: res(15px);
      }
      @include media(sp) {
        margin-top: 15px;
      }

      a {
        display: block;
        padding: 22px;
        @include media(pc-s) {
          padding: res(22px);
        }
        @include media(sp) {
          padding: 22px;
        }

        transition: opacity 0.2s;
        &:hover {
          opacity: 0.8;
          transition: opacity 0.2s;
        }
      }

      &::before {
        position: absolute;
        content: "";
        width: 17px;
        height: 17px;
        border: 0px;
        border-top: solid 2px #fff;
        border-right: solid 2px #fff;
        -ms-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 50%;
        right: 25px;
        margin-top: -7px;
        @include media(pc-s) {
          width: res(17px);
          height: res(17px);
          right: res(25px);
          margin-top: res(-7px);
        }
      }
    }

    &.-tel {
      margin-top: 20px;

      @include media(pc-s) {
        margin-top: res(20px);
      }
      @include media(sp) {
        margin-top: 16px;
        text-align: center;
      }

      a {
        transition: opacity 0.2s;

        @include media(sp) {
          display: block;
        }

        &:hover {
          opacity: 0.8;
          transition: opacity 0.2s;
        }
      }

      img {
        max-width: 290px;
        width: 100%;
        @include media(pc-s) {
          max-width: res(290px);
        }
        @include media(sp) {
          max-width: 212px;
        }
      }
    }
  }

  &__tel {
    margin-top: 40px;
    @include media(pc-s) {
      margin-top: res(40px);
    }
    @include media(sp) {
      margin-top: 30px;
    }
  }

  &__web {
    @include media(sp) {
      margin-top: 30px;
    }

    &>picture{
      display: block;
      width: 100%;

      &>img{
        width: 100%;
      }
    }
  }

  &__title {
    font-size: 2.4rem;
    line-height: 1.583333333;
    text-align: left;
    color: #002b54;
    font-weight: bold;
    @include media(pc-s) {
      font-size: res(2.4rem);
    }
    @include media(sp) {
      text-align: center;
      margin-top: 16px;
      font-size: 1.7rem;
      line-height: 1.529411765;
    }
  }
}

.smartmoverpayFlex {
  display: flex;
  justify-content: center;
  @include media(sp) {
    flex-direction: column;
    align-items: center;
  }
}

.smartmoverpayFlexBox {
  width: 280px;
  margin-top: 50px;
  border: 3px solid #002b54;
  border-radius: 20px;

  @include media(pc-s) {
    width: res(280px);
    margin-top: res(50px);
  }
  @include media(sp) {
    margin-top: 30px;
    border-radius: 15px;
  }

  &:last-child {
    margin-left: 52px;
    @include media(pc-s) {
      margin-left: res(52px);
    }
    @include media(sp) {
      margin-left: 0;
      margin-top: 20px;
    }
  }

  .box {
    text-align: center;
    color: #002b54;

    &__title {
      padding: 20px 0;
      font-size: 2.2rem;
      font-weight: bold;
      letter-spacing: track(100);
      background-color: #fff46c;
      border-radius: 20px 20px 0 0;
      border-bottom: 1px solid #002b54;
      @include media(pc-s) {
        padding: res(20px) 0;
        font-size: res(2.2rem);
      }
      @include media(sp) {
        padding: 12px 0;
        border-radius: 15px 15px 0 0;
      }
    }

    &__text {
      padding: 24px 0;
      font-size: 2.5rem;
      font-weight: bold;
      letter-spacing: track(50);
      @include media(pc-s) {
        padding: res(24px) 0;
        font-size: res(2.5rem);
      }
      @include media(sp) {
      }
    }
  }
}

.smartmoverpayBox {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  margin-top: 35px;
  border: 3px solid #002b54;
  border-radius: 20px;
  text-align: center;
  color: #002b54;
  @include media(pc-s) {
    max-width: res(970px);
    margin-top: res(35px);
  }
  @include media(sp) {
    margin-top: 18px;
    border-radius: 15px;
  }

  &.-pop {
    position: relative;
    @include media(sp) {
      margin-top: 30px;
    }

    &::before {
      position: absolute;
      content: "";
      background-image: url(../images/smartmover/pop.svg);
      background-repeat: no-repeat;
      background-size: contain;
      width: 192px;
      height: 108px;
      top: -55px;
      right: -40px;

      @include media(pc-s) {
        width: res(192px);
        height: res(108px);
        top: res(-55px);
        right: res(-40px);
      }

      @include media(tb) {
        top: -44px;
        right: -16px;
      }

      @include media(sp) {
        width: 108px;
        height: 60px;
        right: -10;
        top: -30px;
      }
    }
  }

  &__title {
    padding: 17px 0;
    font-size: 2.3rem;
    font-weight: bold;
    letter-spacing: track(100);
    background-color: #fff46c;
    border-radius: 16.5px 16.5px 0 0;
    border-bottom: 1px solid #002b54;
    @include media(pc-s) {
      padding: res(17px) 0;
      font-size: res(2.3rem);
    }
    @include media(sp) {
      font-size: 1.7rem;
      border-radius: 13px 13px 0 0;

      .none {
        display: none;
      }

      &.-big {
        font-size: 2rem;
        line-height: 1.333;
      }

      .kakko {
        position: relative;
        padding: 0;
        font-size: 1.5rem;
        text-align: center;

        &::before {
          position: absolute;
          content: "（";
          font-size: 4.2rem;
          font-weight: 100;
          left: -45px;
          top: 0;
        }
        &::after {
          position: absolute;
          content: "）";
          font-size: 4.2rem;
          font-weight: 100;
          top: 0;
          right: -36px;
        }
      }
    }
  }

  &__container {
    display: flex;
    align-items: center;

    @include media(sp) {
      flex-direction: column;
    }

    .box {
      position: relative;
      width: 50%;
      padding: 22px 0;

      @include media(pc-s) {
        padding: res(22px) 0;
      }
      @include media(sp) {
        width: 100%;
        padding: 22px 0;
      }

      &__text {
        font-size: 2.7rem;
        font-weight: 600;

        @include media(pc-s) {
          font-size: res(2.7rem);
        }
        @include media(sp) {
          font-size: 2rem;
        }
      }

      &:first-child {
        @include media(sp) {
          border-bottom: 1px solid $color-main;
        }

        &::after {
          content: "";
          position: absolute;
          top: 50%;
          right: 0;
          transform: translateY(-50%);
          width: 1px;
          height: 44px;
          background-color: $color-main;

          @include media(pc-s) {
            height: res(44px);
          }
          @include media(sp) {
            display: none;
          }
        }
      }
    }
  }
}

.smartmoverpayBoxFlex {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  overflow: hidden;

  @include media(sp) {
    flex-direction: column;
    align-items: stretch;
  }

  .box {
    @include media(sp) {
      border-bottom: 1px solid #002b54;

      &:last-child {
        border-bottom: none;
      }
    }

    &__img {
      a {
        transition: opacity 0.2s;
        &:hover {
          opacity: 0.8;
          transition: opacity 0.2s;
        }
      }
    }

    @include media(tb) {
      &.-w1 {
        width: 120px;

        .box__text {
          padding: 0;
        }
      }
      &.-w2 {
        width: 200px;

        .box__text {
          padding: 0 40px;
        }
      }
    }
    @include media(sp) {
      &.-w1 {
        width: auto;

        .box__text {
          flex-direction: row;
          justify-content: center;
          padding: 18px;
          font-size: 2rem;
        }
      }
      &.-w2 {
        width: auto;

        .box__text {
          flex-direction: row;
          justify-content: center;
          padding: 18px;
          font-size: 2rem;
        }
      }
    }

    &__text {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1;
      padding: 0 24px;
      font-size: 2.6rem;
      font-weight: bold;
      line-height: 1.3;

      @include media(pc-s) {
        padding: 0 res(24px);
        white-space: nowrap;
        font-size: res(2.6rem);
      }
      @include media(tb) {
        padding: 0 12px;
        min-width: 75px;
        font-size: 2rem;
      }
      @include media(sp) {
        flex-direction: row;
        justify-content: center;
        white-space: wrap;
        padding: 18px;
        font-size: 2rem;
      }

      // &::before {
      //   position: absolute;
      //   content: "";
      //   height: 167px;
      //   width: 1px;
      //   background-color: #002b54;
      //   right: 0;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   @include media(pc-s) {
      //     height: 122px;
      //   }
      //   @include media(tb) {
      //     height: 130px;
      //   }
      //   @include media(sp) {
      //     content: none;
      //   }
      // }

      span {
        font-size: 2rem;
        line-height: 1.8;
        @include media(pc-s) {
          font-size: res(2rem);
        }
        @include media(tb) {
          font-size: 1.2rem;
        }
        @include media(sp) {
          font-size: 1.4rem;
          margin-left: 8px;
        }
      }
    }

    &__img {
      width: 100%;
      max-width: 290px;
      margin: 0 auto;
      padding: 20px 20px;
      @include media(pc-s) {
        padding: res(20px) res(20px);
      }
      @include media(tb) {
        padding: 10px 10px;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      @include media(sp) {
        padding: 12px 8px;
      }
    }
  }

  &.-w4 {
    display: flex;
    flex-direction: column;
    @include media(sp) {
      flex-direction: row;
    }
  }

  .miniflexbox {
    display: flex;
    // align-items: center;
    width: 100%;

    @include media(sp) {
      width: 50%;
      flex-direction: column;
    }

    &__box {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 25%;
      height: 80px;
      border-right: 1px solid #002b54;

      @include media(pc-s) {
        height: res(80px);
      }

      @include media(sp) {
        width: 100%;
        height: 62px;
        border-right: 1px dotted #002b54;
        border-bottom: 1px solid #002b54;
        // padding: 10px;
        font-size: 1.8rem;
      }

      &:last-child {
        border-right: none;

        @include media(sp) {
          border-right: 1px dotted #002b54;
          border-bottom: none;
        }
      }
    }

    &__text {
      padding: 0;
      font-size: 1.8rem;
      font-weight: bold;
      letter-spacing: track(50);
      line-height: 1.2;

      @include media(pc-s) {
        font-size: res(1.8rem);
      }
      @include media(sp) {
        width: 100%;
        font-size: 1.8rem;
        line-height: 1.1;
      }

      &.-p {
        @include media(sp) {
          padding: 20px;
        }
      }
      span {
        font-size: 1.5rem;
        @include media(pc-s) {
          font-size: res(1.5rem);
        }
        @include media(sp) {
          font-size: 1.4rem;
        }
      }
    }
  }

  .flexbox {
    display: flex;
    width: 100%;

    @include media(sp) {
      width: 50%;
      flex-direction: column;
    }

    &__text {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 25%;
      height: 80px;
      font-size: 2.6rem;
      font-weight: bold;
      letter-spacing: track(50);
      border-top: 1px dotted #002b54;
      border-right: 1px solid #002b54;

      @include media(pc-s) {
        height: res(80px);
        font-size: res(2.6rem);
      }
      @include media(sp) {
        width: 100%;
        height: 62px;
        border-top: none;
        border-right: none;
        border-bottom: 1px solid #002b54;
        font-size: 2rem;
      }

      &:last-child {
        border-right: none;
        @include media(sp) {
          border-bottom: none;
        }
      }
    }
  }

  &.-w3 {
    display: flex;
    flex-direction: column;
    @include media(sp) {
    }
  }

  .graph {
    display: flex;
    width: 100%;
    @include media(sp) {
      flex-direction: column;
    }

    &.-bt {
      border-top: 1px solid #002b54;
      @include media(pc-s) {
      }
      @include media(sp) {
      }
    }

    &__head {
      display: flex;
      justify-content: center;
      flex-direction: column;
      width: 174px;
      padding: 22px;
      background-color: #fff46c;
      font-size: 2.3rem;
      letter-spacing: track(50);
      line-height: 1.347826087;
      font-weight: bold;
      @include media(pc-s) {
        width: res(174px);
        padding: res(22px);
        font-size: res(2.3rem);
      }
      @include media(sp) {
        align-items: stretch;
        width: 100%;
        padding: 20px;
        border-bottom: 1px solid #002b54;
        font-size: 2rem;
      }

      &.-rd {
        border-radius: 0 0 0 16.5px;
        @include media(sp) {
          border-radius: 0;
        }
      }
    }

    &__body {
      width: 340px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 32px 0;
      font-size: 2.3rem;
      line-height: 1.217391304;
      font-weight: bold;
      border-left: 1px solid #002b54;
      border-right: 1px dotted #002b54;
      @include media(pc-s) {
        width: res(340px);
        padding: res(32px) 0;
        font-size: res(2.3rem);
      }
      @include media(tb) {
        font-size: 1.6rem;
      }
      @include media(sp) {
        width: 100%;
        padding: 22px;
        border-left: none;
        border-right: none;
        border-bottom: 1px dotted #002b54;
        font-size: 1.7rem;

        &.-mini {
          padding: 14px;
        }
      }

      span {
        font-size: 1.8rem;
        line-height: 1.5;
        @include media(pc-s) {
          font-size: res(1.8rem);
        }
        @include media(tb) {
          font-size: 1.4rem;
        }
        @include media(sp) {
          font-size: 1.7rem;
        }
      }
    }

    &__foot {
      display: flex;
      justify-content: center;
      flex-direction: column;
      @include media(pc-s) {
      }
      @include media(sp) {
        padding: 16px 0;
      }

      .box {
        display: flex;
        align-items: center;
        padding-left: 30px;
        @include media(pc-s) {
          padding-left: res(30px);
        }
        @include media(sp) {
          margin: 0 auto;
          padding-left: 0;
        }
      }

      .item {
        width: 210px;
        font-size: 2.6rem;
        font-weight: bold;
        text-align: left;
        @include media(pc-s) {
          width: res(210px);
          font-size: res(2.6rem);
        }
        @include media(sp) {
          width: auto;
          font-size: 2rem;

          & + .item {
            margin-left: 14px;
          }
        }

        span {
          margin-right: 8px;
          padding: 6px 20px;
          font-size: 2rem;
          color: #fff;
          background-color: #002b54;
          @include media(pc-s) {
            margin-right: res(8px);
            padding: res(6px) res(20px);
            font-size: res(2rem);
          }
          @include media(sp) {
            font-size: 1.4rem;
            padding: 5px 8px;
          }
        }
      }
    }
  }

  .box{
    position: relative;

    &::before{
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 1px;
      transform: translateY(-50%);
      height: 140px;
      background-color: #002b54;

      @include media(sp) {
        content: none;
      }
    }

    &:last-child{
      &::before{
        content: none;
      }
  }

  &__img{
    filter: drop-shadow(0 0 4px rgba(0,0,0,0.2));
  }
  }
}

.smartmoverpayNote {
  display: flex;
  max-width: 970px;
  margin: 10px auto 0;

  @include media(pc-s) {
    max-width: res(970px);
    margin-top: res(10px);
  }
  @include media(sp) {
    flex-direction: column;
    align-items: center;
    max-width: none;
  }

  &__txt {
    font-size: 1.5rem;
    color: $color-main;

    @include media(pc-s) {
      font-size: res(1.5rem);
    }
    @include media(sp) {
      text-align: center;
      font-size: 1.3rem;
      line-height: 1.3;
    }

    &:first-child {
      margin-right: 0.5em;

      @include media(sp) {
        margin-right: 0;
      }
    }
  }
}

.smartmoverAreaBox {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 40px;
  background-color: #fff46c;
  border-radius: 20px;
  @include media(pc-s) {
    max-width: res(970px);
    margin-top: res(50px);
    padding: res(40px);
  }
  @include media(sp) {
    padding: 20px;
  }

  &__img {
    position: relative;

    a {
      position: absolute;
      width: 255px;
      height: 255px;
      z-index: 1;
      right: -45px;
      bottom: -55px;
      @include media(pc-s) {
        width: res(255px);
        height: res(255px);
        right: res(-45px);
        bottom: res(-55px);
      }
      @include media(sp) {
        width: 112px;
        height: 112px;
        right: -25px;
        bottom: -25px;
      }

      &:hover {
        opacity: 0.9;
      }
    }
  }
}

.smartmoverAreaBoxFlex {
  display: flex;
  align-items: center;
  margin-top: 40px;
  @include media(pc-s) {
    margin-top: res(40px);
  }
  @include media(sp) {
    flex-direction: column;
    margin-top: 38px;
  }

  &__img {
    @include media(sp) {
      margin: 0 auto;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-left: 30px;
    padding: 12px 0;
    padding-left: 30px;
    font-size: 1.9rem;
    line-height: 1.842105263;
    letter-spacing: track(100);
    color: #002b54;
    border-left: 1px solid #000;
    @include media(pc-s) {
      margin-left: res(30px);
      padding: res(12px) 0;
      padding-left: res(30px);
      font-size: res(1.9rem);
    }
    @include media(sp) {
      max-width: 380px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      padding-left: 0;
      margin-top: 30px;
      padding-top: 26px;
      border-left: none;
      border-top: 1px solid #000;
      text-align: center;
    }
  }
}

.smartmoverAppBox {
  max-width: 970px;
  width: 100%;
  margin: 0 auto;
  margin-top: 50px;
  padding: 40px;
  padding-bottom: 50px;
  background-color: #fff46c;
  text-align: center;
  border-radius: 20px;
  @include media(pc-s) {
    max-width: res(970px);
    margin-top: res(50px);
    padding: res(40px);
    padding-bottom: res(50px);
  }
  @include media(sp) {
    margin-top: 30px;
    padding: 20px;
  }

  &__img {
    margin-top: 30px;
    @include media(pc-s) {
      margin-top: res(30px);
    }
    @include media(sp) {
    }

    &>picture{
      @include media(sp) {
        display: block;
        margin: 0 auto;
        width: 220px;

        &>img{
          width: 100%;
        }
      }
    }
  }

  &__title {
    &.-mt {
      margin-top: 50px;
      @include media(pc-s) {
        margin-top: res(50px);
      }
      @include media(sp) {
        margin-top: 30px;
      }
    }

    .box {
      display: flex;
      background-color: #fff;
      color: #002b54;
      border-radius: 5em;
      border: 3px solid #002b54;
      font-weight: bold;
      @include media(sp) {
        border-radius: 13px;
      }

      &__title {
        max-width: 276px;
        width: 100%;
        padding: 21px;
        font-size: 2.5rem;
        border-right: 1px solid #002b54;
        @include media(pc-s) {
          max-width: res(276px);
          padding: res(21px);
          font-size: res(2.5rem);
        }
        @include media(sp) {
          max-width: 92px;
          padding: 22px;
          font-size: 2rem;
          line-height: 1.181818182;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__text {
        max-width: 610px;
        width: 100%;
        padding: 21px;
        font-size: 2.3rem;
        text-align: center;
        @include media(pc-s) {
          max-width: res(610px);
          padding: res(21px);
          font-size: res(2.3rem);
        }
        @include media(sp) {
          font-size: 1.7rem;
          line-height: 1.529411765;
          padding: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }

  &__button {
    max-width: 477px;
    width: 100%;
    margin: 0 auto;
    position: relative;
    margin-top: 46px;
    font-size: 2.6rem;
    text-align: center;
    letter-spacing: track(100);
    background-color: #0076c7;
    border-radius: 12px;
    color: #fff;
    font-weight: bold;
    @include media(pc-s) {
      max-width: res(477px);
      margin-top: res(46px);
      font-size: res(2.6rem);
    }
    @include media(sp) {
      margin-top: 16px;
      font-size: 2rem;
    }

    a {
      display: block;
      padding: 33px;
      @include media(pc-s) {
        padding: res(33px);
      }
      @include media(sp) {
        padding: 22px;
      }
      transition: opacity 0.2s;
      &:hover {
        opacity: 0.7;
        transition: opacity 0.2s;
      }
    }

    &::before {
      position: absolute;
      content: "";
      width: 14px;
      height: 14px;
      border: 0px;
      border-top: solid 1px #fff;
      border-right: solid 1px #fff;
      -ms-transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      top: 50%;
      right: 30px;
      margin-top: -7px;
      @include media(pc-s) {
        width: res(14px);
        height: res(14px);
        right: res(30px);
        margin-top: res(-7px);
      }
      @include media(sp) {
      }
    }
  }
}

.appDownload{
  position: relative;
  margin: 0 auto;
  margin-top: 80px;
  width: 100%;
  max-width: 970px;
  padding: 0 20px 20px;
  background-color: #65C4F2;

  @include media(pc-s) {
    margin-top: res(80px);
    padding: 0 res(20px) res(20px);
  }

  @include media(sp) {
    margin-top: 60px;
    padding: 13vw 15px 15px;
  }
}

.appDownload__title{
  width: 700px;
  max-width: 70%;
  margin-left: -20px;

  @include media(pc-s) {
    width: res(700px);
    margin-left: res(-20px);
  }

  @include media(sp) {
    margin-left: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
  }

  &>img{
    width: 100%;
  }
}

.appDownload__logo{
  position: absolute;
  top: -20px;
  right: 50px;
  width: 220px;
  max-width: 20vw;

  @include media(pc-s) {
    top: res(-20px);
    right: res(50px);
    width: res(220px);
  }

  @include media(sp) {
    position: relative;
    top: auto;
    right: auto;
    margin: 0 auto;
    margin-top: 25px;
    width: 130px;
    max-width: 100%;
  }
}

.appDownload__appList{
  margin-top: 35px;
  display: flex;
  justify-content: center;

  @include media(pc-s) {
    margin-top: res(35px);
  }

  @include media(sp) {
    flex-direction: column;
    align-items: center;
  }
}

.appDownload__appItem{
  width: 360px;
  margin-left: 30px;

  @include media(pc-s) {
    width: res(360px);
    margin-left: res(30px);
  }

  @include media(sp) {
    width: 70%;
    max-width: 320px;
    min-width: 240px;
    margin-left: 0;
    margin-top: 15px;
  }

  &:first-child{
    margin-left: 0;
    margin-top: 0;
  }

  &>a{
    display: block;
    width: 100%;
    height: 100%;

    &:hover{
      opacity: 0.8;
    }

    &>img{
      width: 100%;
    }
  }
}

.appDownload__text{
  margin-top: 20px;
  font-size: 1.4rem;
  line-height: line(23,14);
  text-align: center;

  @include media(pc-s) {
    margin-top: res(20px);
    font-size: res(1.4rem);
  }

  @include media(sp) {}
}

.smartmoverAbout {
  margin-top: 100px;
  padding-top: 100px;
  color: #002b54;
  text-align: center;
  border-top: 1px solid #000;
  @include media(pc-s) {
    padding-top: res(100px);
    margin-top: res(100px);
  }
  @include media(sp) {
    padding-top: 60px;
    margin-top: 60px;
  }

  &__title {
    font-size: 2.3rem;
    font-weight: bold;
    @include media(pc-s) {
      font-size: res(2.3rem);
    }
    @include media(sp) {
      font-size: 1.8rem;
    }
  }

  &__text {
    margin-top: 28px;
    font-size: 1.4rem;
    line-height: 1.857142857;
    letter-spacing: track(100);

    @include media(pc-s) {
      margin-top: res(28px);
      font-size: res(1.4rem);
    }
    @include media(sp) {
      margin-top: 12px;
      font-size: 1.4rem;
      line-height: 1.857142857;
      text-align: left;
    }
  }

  &__mini {
    margin-top: 22px;
    font-size: 2.3rem;
    font-weight: bold;
    letter-spacing: track(100);
    @include media(pc-s) {
      margin-top: res(22px);
      font-size: res(2.3rem);
    }
    @include media(sp) {
      font-size: 1.8rem;
      padding-bottom: 20px;
    }
  }

  .serviceContact__button {
    max-width: 477px;
    @include media(pc-s) {
      max-width: res(477px);
    }
    a {
      text-align: center;
    }
  }
}
