/* メインの設定(全体)
------------------------------- */

// common
.anker {
  display: block;
  margin-top: -80px;
  padding-top: 80px;

  @include media(pc-s) {
    margin-top: res(-80px);
    padding-top: res(80px);
  }

  @include media(sp) {
    margin-top: res(-54px);
    padding-top: res(54px);
  }
}

// inner
.inner {
  position: relative;
  max-width: 1000px;
  margin: 0 auto;

  @include media(pc-s) {
    max-width: res(1000px);
  }

  @include media(tb) {
    padding: 0 20px;

    &.-company {
      padding: 0;
    }
  }

  @include media(sp) {
    padding: 0 15px !important;
  }


}

// main
.main {
  display: block;
}

body {
  font-family: $font;
}

// br関連
.d-tb,
.d-sp {
  display: none;
}

@include media(pc-s) {

  .d-pc {
    display: block;
  }

  .d-tb {
    display: none;
  }

  .d-sp {
    display: none;
  }

}

@include media(tb) {

  .d-tb {
    display: block;
  }

}

@include media(sp) {

  .d-pc {
    display: none;
  }

  .d-sp {
    display: block;
  }
}
