// spMenu
.spMenu {
  position: fixed;
  bottom: 0;
  left: 0;
  display: none;
  width: 100%;
  z-index: 100;

  @include media(tb) {
    display: block;
  }

  &__list {
    display: flex;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 60px;

    &.-estimate {
      background-color: $color-sub01;

      .spMenu__img {
        max-width: 32px;

        &::before {
          padding-top: percentage(34px / 32px);
        }
      }

      .spMenu__title {
        color: $color-main;
      }
    }

    &.-contact {
      background-color: $color-main;

      .spMenu__img {
        max-width: 30px;

        &::before {
          padding-top: percentage(23px / 30px);
        }
      }

      .spMenu__title {
        color: $color-wh;
      }
    }

    &>a {
      @include linkall;
    }
  }

  &__img {
    position: relative;
    width: 100%;
    margin-right: 11px;
    z-index: 1;

    &::before {
      content: '';
      display: block;
    }

    &>img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 1.3rem;
    font-weight: 500;
    text-align: center;
  }

  &.none {
    display: none;
  }
}
