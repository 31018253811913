.news {

  &__container {
    position: relative;
    max-width: 1110px;
    width: 100%;
    flex-basis: 90%;

    @include media(pc-s) {
      max-width: res(1110px);
    }

    @include media(tb) {
      padding-right: 120px;
    }

    @include media(sp) {
      padding-right: 0;
    }

  }

  .blog {
    position: relative;
    max-width: 1000px;
    width: 100%;

    &__box {
      position: relative;
      max-width: 1000px;
      width: 100%;
      padding: 100px 70px 94px;

      @include media(pc-s) {
        padding: res(100px) res(70px) res(94px);
      }

      @include media(sp) {
        margin-left: 0;
        margin-right: 0;
        padding: 60px 0 40px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #000;
      }

    }

    &__link {
      margin-top: -118px;
      padding-top: 118px;

      @include media(pc-s) {
        margin-top: res(-118px);
        padding-top: res(118px);
      }

      &:first-child {
        margin-top: -120px;
        padding-top: 120px;

        @include media(pc-s) {
          margin-top: res(-120px);
          padding-top: res(120px);
        }
      }

      @include media(sp) {}

      &:first-child {
        .blog__box {
          padding-top: 0;

          &::before {
            content: none;
          }
        }
      }
    }

    &__content {
      font-size: 1.8rem;

      @include media(pc-s) {
        font-size: res(1.8rem);
      }

      @include media(sp) {
        font-size: 1.8rem;
      }

      p {
        font-weight: 400;
        line-height: 1.8;
      }
    }


    &__contentBox {
      margin-top: 26px;
      font-size: 1.8rem;
      line-height: 1.6;

      @include media(pc-s) {
        margin-top: res(26px);
        font-size: res(1.8rem);
      }

      @include media(sp) {
        margin-top: 20px;
        font-size: 1.8rem;
      }
    }

    &__title {
      margin-top: 24px;
      font-size: 3.2rem;
      font-weight: 500;
      letter-spacing: track(50);
      color: $color-bk;
      line-height: 1.4;

      @include media(pc-s) {
        margin-top: res(24px);
        font-size: res(3.2rem);
      }

      @include media(sp) {
        margin-top: 12px;
        font-size: 1.8rem;
        font-weight: 500;

      }
    }

    &__time {

      &>time {
        font-size: 1.7rem;
        letter-spacing: track(100);
        font-weight: 500;
        color: #7F8080;

        @include media(pc-s) {
          font-size: res(1.7rem);
        }

        @include media(sp) {
          font-size: 1.3rem;

        }
      }
    }

    &__image {

      &>img {
        max-width: 100%;
        width: 100%;
        margin-top: 24px;

        @include media(pc-s) {
          margin-top: res(24px);
        }

        @include media(sp) {
          margin-top: 18px;
        }
      }
    }

    &__text {
      margin-top: 54px;
      font-size: 2rem;

      @include media(pc-s) {
        margin-top: res(54px);
        font-size: res(2rem);
      }

      @include media(sp) {
        margin-top: 30px;
        font-size: 1.8rem;
      }

      p {
        line-height: 1.85;

        a {
          text-decoration: underline;

          &:hover {
            opacity: .7;
          }
        }
      }
    }

    &__btn {
      max-width: 180px;
      margin-top: 40px;

      @include media(pc-s) {
        max-width: res(180px);
        margin-top: res(40px);
      }

      @include media(sp) {
        max-width: 180px;
        margin-top: 22px;
      }

      &>a {
        position: relative;
        display: block;
        padding: 13px 0;
        border-radius: 4em;
        font-size: 1.8rem;
        letter-spacing: track(100);
        color: $color-wh;
        text-align: center;

        @include media(pc-s) {
          padding: res(13px) 0;
          font-size: res(1.8rem);
        }

        @include media(sp) {
          padding: 13px 0;
          font-size: 1.8rem;
        }

        &:hover {
          background-color: #003f76;
        }

        &>span {
          position: relative;
          display: inline-block;
          padding-right: 36px;

          @include media(pc-s) {
            padding-right: res(36px);
          }

          @include media(sp) {
            padding-right: 36px;
          }

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            width: 24px;
            height: 13px;
            background-image: url(../images/common/icon/arrow02.svg);
            background-repeat: no-repeat;
            background-size: contain;

            @include media(pc-s) {
              width: res(24px);
              height: res(13px);
            }

            @include media(sp) {
              width: 24px;
              height: 13px;
            }
          }
        }
      }
    }
  }
}

.news-box {
  position: relative !important;
  display: flex;
  flex-direction: row;
  max-width: 1110px;
  margin: 110px auto 0;

  @include media(pc-s) {

    margin: res(74px) auto 0;

  }

  @include media (tb) {
    padding: 0 30px;
  }


  @include media(sp) {
    flex-direction: column;
    align-items: center;
    margin: 50px auto 0;
  }
}


.sidebar {
  position: absolute;
  flex-basis: 10%;
  right: 0;

  @include media(pc-s) {
    right: 40px;
  }

  @include media(sp) {
    position: static;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 60px;
    padding-right: 0;
  }


  &__title {
    font-size: 1.8rem;
    font-family: $font-en;
    color: $color-bk;
    letter-spacing: track(100);
    font-weight: 400;

    @include media(pc-s) {
      font-size: res(1.8rem);
    }

    @include media(sp) {
      font-size: 1.7rem;
      font-weight: 400;
    }
  }

  &__content {
    display: block;
    margin-top: 23px;
    line-height: 0;
    height: 0;
    opacity: 0;
    transition-duration: .3s;

    .monthly {
      display: none;
    }

    &.is-open {
      line-height: normal;
      /* numberに書き換える*/
      height: auto;
      opacity: 1;

      .monthly {
        display: block;
      }
    }


  }

  &__container {
    margin-top: 40px;
    overflow: hidden;

    @include media(pc-s) {
      margin-top: res(40px);
    }

    @include media(sp) {
      width: 100%;
      margin-top: 24px;
    }
  }

  .sidebar-list {
    padding-top: 20px;
    padding-right: 14px;
    padding-left: 2px;
    border-bottom: 1px solid #040000;
    color: #999899;


    @include media(pc-s) {
      padding-top: res(20px);
      padding-right: res(14px);
    }

    @include media(sp) {
      text-align: center;
      padding-right: 0;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid #040000;
    }


    &__head {
      position: relative;
      padding-right: 25px;
      font-size: 1.7rem;
      font-weight: 400;
      letter-spacing: track(200);
      cursor: pointer;
      transition: .2s;
      padding-top: 1px;

      @include media(pc-s) {
        padding-right: res(25px);
        font-size: res(1.7rem);
      }

      @include media(sp) {
        display: inline-block;
        padding-right: 25px;
        font-size: 1.7rem;
      }

      &:before {
        content: '';
        position: absolute;
        top: 2px;
        right: 4px;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        border-bottom: solid 1px #999899;
        border-right: solid 1px #999899;

        @include media(pc-s) {
          width: res(10px);
          height: res(10px);
        }

        @include media(sp) {
          top: 4px;
          width: 8px;
          height: 8px;
        }
      }

      &:hover {
        opacity: .7;
      }

      &.is-active {
        &:before {
          right: 0;
          transform: rotate(225deg) translateY(-10px);
        }
      }
    }

    .monthly {
      margin-bottom: 30px;

      @include media(pc-s) {
        margin-bottom: res(30px);
      }

      @include media(sp) {
        margin-bottom: 24px;
      }

      li {
        margin-top: 15px;
        padding-top: 1px;

        @include media(pc-s) {
          margin-top: res(15px);
        }

        @include media(sp) {
          margin-top: 25px;
        }

        &:first-child {
          margin-top: 0;
        }

        a {
          font-size: 1.4rem;
          font-weight: 400;
          letter-spacing: track(200);

          @include media(pc-s) {
            font-size: res(1.4rem);
          }

          @include media(sp) {
            padding-right: 10px;
            font-size: 1.4rem;
            text-align: center;
          }

          &:hover {
            opacity: .7;

            @include media(tb) {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}


.pop {
  &.bottom {
    display: block;
    bottom: 700px;

    @include media(pc-s) {
      bottom: res(700px);
    }

    @include media(sp) {}
  }
}

@media all and (-ms-high-contrast: none) {
  .pop {
    &.bottom {
      bottom: 610px;
    }
  }
}


_:-ms-lang(x)::backdrop,

.pop {
  &.bottom {
    margin-bottom: 500px;
  }
}
