// contact
.contact {

  &__form {
    margin-top: 80px;

    @include media(pc-s) {
      margin-top: res(80px);
    }

    @include media(sp) {
      margin-top: 50px;
    }
  }

  // form__txt
  &__txt {
    margin-top: 68px;
    font-size: 1.5rem;
    letter-spacing: track(100);
    line-height: line(31, 15);
    text-align: center;

    @include media(pc-s) {
      margin-top: res(68px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      margin-top: 34px;
      font-size: 1.2rem;
    }
  }
}
