// businessArea
.businessArea {
  margin-top: 112px;
  padding: 110px 0 114px;
  text-align: center;
  background-color: #F4F4E9;

  @include media(pc-s) {
    margin-top: res(112px);
    padding: res(110px) 0 res(114px);
  }

  @include media(sp) {
    margin-top: 54px;
    padding: 54px 0 56px;
  }

  &__title {
    font-size: 5.4rem;
    letter-spacing: track(300);
    font-weight: 500;
    text-align: center;

    @include media(pc-s) {
      font-size: res(5.4rem);
    }

    @include media(sp) {
      font-size: 2.7rem;
      letter-spacing: track(200);
    }
  }

  &__text {
    margin-top: 54px;
    font-size: 1.7rem;
    letter-spacing: track(20);

    @include media(pc-s) {
      margin-top: res(54px);
      font-size: res(1.7rem);
    }

    @include media(sp) {
      margin-top: 24px;
      font-size: 1.4rem;
      line-height: 1.857142857;
    }

    &.-big {
      margin-top: 28px;
      font-size: 2.8rem;
      font-weight: 400;
      letter-spacing: track(100);


      @include media(pc-s) {
        margin-top: res(28px);
        font-size: res(2.8rem);
      }

      @include media(sp) {
        margin-top: 20px;
        font-size: 1.8rem;
        letter-spacing: track(50);
        line-height: 1;
      }
    }


  }

  .businessAreaBox {
    max-width: 1000px;
    width: 100%;
    margin: 0 auto;
    margin-top: 28px;
    padding-top: 26px;
    padding-bottom: 28px;
    background-color: $color-wh;
    border-radius: 15px;

    @include media(pc-s) {
      margin-top: res(28px);
      padding-top: res(26px);
      padding-bottom: res(28px);
    }

    @include media(sp) {
      max-width: 330px;
      padding-top: 20px;
      padding-bottom: 24px;
    }

    &__title {
      font-size: 2.7rem;
      letter-spacing: track(100);
      color: $color-main;
      font-weight: 500;

      @include media(pc-s) {
        font-size: res(2.7rem);
      }

      @include media(sp) {
        font-size: 2rem;
        line-height: 1.55;
      }
    }

    &__text {
      margin-top: 11px;
      font-size: 1.4rem;
      letter-spacing: track(50);

      @include media(pc-s) {
        margin-top: res(11px);
        font-size: res(1.4rem);
      }

      @include media(sp) {
        margin-top: 16px;
        font-size: 1.2rem;
        line-height: 1.666666667;
      }
    }
  }

}
