/* スライドショー
-------------------------------------------- */
.kv {
  position: relative;
  width: 100%;
  margin-top: 120px;

  @include media(pc-s) {
    margin-top: res(120px);
  }

  @include media(tb) {
    margin-top: 70px;
  }

  @include media(sp) {
    margin-top: 60px;
  }

  .swiper-container {
    width: 100%;
  }

  .slide-img {
    width: 100%;
  }

  .slide-img img {
    max-width: none;
    width: 100%;
  }

  &__copy {
    position: absolute;
    bottom: 10%;
    left: 10%;
    z-index: 1;
    max-width: 762px;
    width: 100%;

    @include media(pc-s) {
      max-width: res(762px);
    }

    @include media(tb) {
      max-width: 544px;
      left: 4%;
    }

    @include media(sp) {
      left: 4%;
      max-width: 320px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: percentage(155px / 762px);
    }

    &>img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}


/* スマホ横
------------------------------------------------ */
@media only screen and (orientation:landscape) and (max-width:813px) {}
