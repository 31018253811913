.pagination-box {
  @include media(sp) {
    margin: 0 auto;
    text-align: center !important;
  }
}

.pagination-num {
  display: flex !important;
  flex-wrap: nowrap !important;
  margin-left: 34px;

  @include media(pc-s) {
    margin-left: res(34px);
  }

  @include media(sp) {
    margin-left: -15px !important;
    margin-right: -15px !important;
    justify-content: center;
  }




  .page-numbers {
    position: relative;
    font-size: 2.2rem;
    text-align: center;
    color: $color-main;
    padding: 3px 35px;

    @include media(pc-s) {
      font-size: res(2.2rem);
      padding: res(3px) res(35px);
    }

    @include media(tb) {
      padding: res(3px) 15px;
    }

    @include media(sp) {
      font-size: 2.2rem;
      padding: 3px 16px;
    }

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 25px;
      background-color: $color-bk;

      @include media(pc-s) {
        height: res(25px);
      }

      @include media(sp) {
        height: 25px;
      }
    }

    &:first-child {
      &::before {
        content: none;
      }
    }

    &:last-child {
      &::before {
        content: none;
      }
    }

    &.current {
      &:last-child {
        &::before {
          content: "";
        }
      }
    }

    &.dots {
      color: $color-bk;
    }
  }

  .next,
  .prev {
    padding: 0 !important;

    @include media(sp) {
      img {
        width: 30px;
      }
    }

    &::before {
      content: none;

      @include media(sp) {
        content: "";
      }
    }

    &+.page-numbers {
      &::before {
        content: none;

      }
    }
  }

  .prev {
    img {
      transform: scale(-1, 1);
    }
  }

  .next,
  .prev {
    display: none !important;

    @include media(sp) {
      display: block !important;
    }

  }


  a.page-numbers,
  .pager .current {
    font-size: 2.2rem;
    text-align: center;
    color: $color-bk;

    @include media(pc-s) {
      font-size: res(2.2rem);
    }

    @include media(sp) {
      font-size: 2.2rem;
    }

    &:hover {
      opacity: .5;
    }
  }

  .pager .current {
    font-size: 2.2rem;
    text-align: center;

    @include media(pc-s) {
      font-size: res(2.2rem);
    }

    @include media(sp) {
      font-size: 1.7rem;
    }

    &:hover {
      opacity: .5;
    }
  }
}



.pagination-box {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 26px;

  @include media(pc-s) {
    margin-top: res(26px);
  }

  @include media(sp) {
    flex-direction: column;
    margin-top: 30px;
  }
}

.pagination {
  display: flex;
  justify-content: flex-end;

  @include media(sp) {
    display: none;
  }

  .page-numbers {
    display: none;

    &:first-child,
    &:last-child {
      display: block;
    }
  }

  .current {

    &:first-child,
    &:last-child {
      display: none;
    }
  }

  a {
    img {
      width: 30px;

      @include media(pc-s) {
        width: res(30px);
      }

      @include media(sp) {
        width: 30px;
      }

      &:hover {
        opacity: .6;
      }
    }

    &:first-child {
      margin-right: 60px;

      @include media(pc-s) {
        margin-right: res(60px);
      }

      @include media(sp) {
        margin-right: 60px;
      }

      img {
        transform: scale(-1, 1);
      }
    }
  }

  &__next {

    a {
      margin-right: 60px;

      @include media(pc-s) {
        margin-right: res(60px);
      }

      @include media(sp) {
        margin-right: 60px;
      }

      img {
        transform: scale(-1, 1);
      }
    }
  }
}
