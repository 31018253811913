// thanks
.thanks {
  padding-bottom: 40px;

  @include media(pc-s) {
    padding-bottom: 40px;
  }

  @include media(sp) {
    padding-bottom: 14px;
  }

  &__txt {
    margin-top: 88px;
    font-size: 1.5rem;
    letter-spacing: track(100);
    line-height: line(31, 15);
    text-align: center;

    @include media(pc-s) {
      margin-top: res(88px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      margin-top: 46px;
      font-size: 1.3rem;
      letter-spacing: track(50);
    }
  }

  &__btn {
    max-width: 420px;
    width: 100%;
    margin: 56px auto 0;

    @include media(pc-s) {
      max-width: res(420px);
      margin-top: res(56px);
    }

    @include media(sp) {
      margin-top: 34px;
    }

    &>a {
      position: relative;
      display: block;
      padding: 27px 0;
      border: 2px solid $color-main;
      border-radius: .5em;
      font-size: 1.9rem;
      font-weight: 500;
      letter-spacing: track(100);
      color: $color-main;
      text-align: center;

      @include media(pc-s) {
        padding: res(27px) 0;
        font-size: res(1.9rem);
      }

      @include media(sp) {
        padding: 20px 0;
        font-size: 2rem;
      }

      &::before {
        @include arrow;
        top: 50%;
        left: 5.5%;
        border-top: solid 1px $color-main;
        border-right: solid 1px $color-main;
        transform: translateY(-50%) rotate(-135deg);
      }

      &:hover {
        opacity: .7;
      }
    }
  }
}
