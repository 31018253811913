/*ここに変数を定義*/

//font-family
$font: "Noto Sans JP", sans-serif;
$font-en: 'Spartan',
sans-serif;

//font-size
$font-size: 10px;

// カラー
$color-bk: #000000;
$color-wh: #fff;
$color-main: #002B51;
$color-sub01: #FCD16F;
$color-sub02: #EFB300;
$color-bg: #F4F4E9;
$color-text :#231815;

// clearfix  ※floatを使用した親要素に
@mixin clearfix {
  &::after {
    content: "";
    display: block;
    clear: both;
  }
}

// aタグの範囲を全体へ ※親要素にpoition: reletive;
@mixin linkall {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// position-center ※親要素にpoition: reletive;
@mixin center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//.wrapperから出したい(width:100%にしたい)場合ネガティブマージンを使う。
@mixin re-m {
  margin: 0 calc(50% - 50vw);
}

//.wrapperから出すがpaddingは.wrapperに合わせたい場合(主に背景色だけ100%に使う事が多い)
@mixin re-p {
  margin: 0 calc(50% - 50vw);
  padding: 0 calc(50vw - 50%);
}

// CSSの矢印（親にrelative + ::beforeに入れる）
@mixin arrow {
  content: '';
  position: absolute;
  top: calc(50% - 1px);
  right: 5.5%; //要調整
  display: block;
  width: 14px; //要調整
  height: 14px; //要調整
  border-top: solid 1px #000; //要調整
  border-right: solid 1px #000; //要調整
  transform: translateY(-50%) rotate(45deg);

  @include media(pc-s) {
    width: res(14px); //要調整
    height: res(14px); //要調整
  }

  @include media(sp) {
    right: 6%; //要調整
    width: 14px; //要調整
    height: 14px; //要調整
  }
}


// breakpoint
$breakpoints: (pc-s: "screen and (max-width: 1400px)",
  tb: "screen and (max-width: 1120px)",
  sp: "screen and (max-width: 670px)",
  se: "screen and (max-width: 374px)",
);

@mixin media($breakpoint) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
