.btn-wrap {
  cursor: pointer;

  &:hover {
    opacity: .6;
  }
}

.modal {
  position: fixed;
  display: flex;
  align-items: center;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: none;
  opacity: 0;
  z-index: -1;

  @include media(pc-s) {}

  @include media(sp) {}
}

.modal-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(90, 90, 100, 0.8);

  @media screen and (width: 672px) {}

  @include media(sp) {}
}

.modal-inner {
  width: 840px;
  height: 360px;
  margin: auto;
  background: #fff;
  z-index: 1;

  @include media(pc-s) {
    width: res(840px);
    height: res(360px);
  }

  @include media(sp) {
    width: 306px;
    height: 520px;
  }

  .modal__iframe {
    position: relative;
    max-width: 840px;
    width: 100%;

    @include media(pc-s) {
      max-width: res(840px);
    }

    @include media(sp) {
      max-width: 306px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: percentage(360 / 840);

      @include media(sp) {
        padding-top: percentage(510 / 300);
      }
    }

    &>iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}



.modal-content {
  position: relative;
  display: flex;
  width: 100%;

  @media screen and (max-width: 306px) {
    flex-direction: column;
    justify-content: space-between;
  }
}


.modal-close {
  position: absolute;
  display: block;
  width: 15px;
  height: 15px;
  top: -18px;
  right: 0;
  cursor: pointer;

  @include media(pc-s) {
    width: res(15px);
    height: res(15px);
    top: res(-18px);
  }

  @include media(sp) {
    width: 15px;
    height: 15px;
  }

  &::before,
  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 1px;
    background: #fff;
    transform: rotate(45deg);
    transform-origin: 0% 50%;
    position: absolute;
    top: calc(14% - 5px);
    left: 14%;

    @include media(pc-s) {
      top: calc(#{res(14%)} - #{res(5px)});
    }

    @include media(sp) {
      top: calc(14% - 5px);
    }
  }

  &::after {
    transform: rotate(-45deg);
    transform-origin: 100% 50%;
    left: auto;
    right: 14%;
  }
}



.model-title {
  position: relative;
  padding-left: 15px;
  font-size: 2.1rem;
  letter-spacing: track(100);
  font-weight: 500;
  z-index: 100;

  @media screen and (width: 672px) {
    padding-left: res(15px);
    font-size: res(2.1rem);
  }

  @media screen and (width: 306px) {
    font-size: 1.5rem;
  }

  &::before {
    position: absolute;
    content: "";
    width: 5px;
    height: 18px;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    background: #000;

    @media screen and (width: 672px) {
      width: res(5px);
      height: res(18px);
    }

    @media screen and (width: 306px) {
      font-size: 1.5rem;
    }
  }

  &.-twoline {
    line-height: 1.5;

    @media screen and (width: 672px) {}

    @media screen and (width: 306px) {}

    &::before {
      height: 50px;

      @media screen and (width: 672px) {
        height: res(50px);
      }

      @media screen and (width: 306px) {
        height: 38px;
      }
    }
  }
}

.model-img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // margin: 0 auto;
  margin-top: 18px;
  max-width: 354px;
  width: 100%;

  @media screen and (width: 672px) {
    margin-top: res(18px);
    width: res(354px);
  }

  @media screen and (width: 306px) {
    margin-top: 20px;
    max-width: 255px;

  }

  &>img {
    width: 100%;

    @media screen and (width: 672px) {}

    @include media(sp) {}
  }

  &.-twoline {
    margin-top: 10px;

    @media screen and (width: 672px) {
      margin-top: res(10px);
    }

    @media screen and (width: 306px) {
      margin-top: 10px;
    }
  }
}

.model-list {
  display: flex;
  flex-wrap: wrap;
  width: 50%;

  @media screen and (max-width: 306px) {
    width: 100%;
    height: 260px;
  }
}

.model-item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 180px;

  @media screen and (width: 672px) {
    height: res(180px);
  }

  @media screen and (width: 306px) {
    height: 131px;
  }

  a {
    @include linkall;
  }

  &:nth-child(2n) {
    border-left: 2px solid #fff;

    @media screen and (width: 672px) {}

    @media screen and (width: 306px) {
      border-left: 1px solid #fff;

    }
  }

  &:nth-child(n+3) {
    border-top: 2px solid #fff;

    @media screen and (width: 672px) {}

    @media screen and (width: 306px) {
      border-top: 1px solid #fff;
    }
  }

  &:hover {
    background: #003F76;
  }
}

.model-text {
  margin-top: 28px;
  color: $color-wh;
  text-align: center;
  font-size: 1.6rem;
  font-weight: 500;
  letter-spacing: track(120);
  line-height: 1.4;

  @media screen and (width: 672px) {
    margin-top: res(28px);
    font-size: res(1.6rem);
  }

  @media screen and (width: 306px) {
    margin-top: 20px;
    font-size: 1.2rem;
  }
}

.modal {
  &.is-open {
    animation: modal_open .5s ease forwards;

    .modal-inner {
      animation: modal_inner_open .5s ease forwards;
    }
  }

  &.is-close {
    animation: modal_close .5s ease forwards;

    .modal-inner {
      animation: modal_inner_close .5s ease forwards;
    }
  }
}

@keyframes modal_open {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
    z-index: 10000;
  }
}

@keyframes modal_inner_open {
  0% {
    opacity: 0;
    transform: translateY(-20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes modal_close {
  0% {
    z-index: 10000;
    opacity: 1;
  }

  100% {
    opacity: 0;

  }
}

@keyframes modal_inner_close {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
    opacity: 0;

  }
}


@media only screen and (orientation:landscape) and (max-width:668px) {

  .modal-inner {

    width: 600px;
    height: 257px;
  }


  .modal-content {}

  .modal__iframe {
    max-width: 600px !important;

    &::before {
      padding-top: percentage(360 / 840) !important;
    }
  }

  .model-title {
    padding-left: res(15px);
    font-size: res(2.1rem);

    &::before {
      width: res(5px);
      height: res(18px);
    }

    &.-twoline {
      line-height: 1.5;

      &::before {
        height: res(50px);
      }
    }
  }

  .model-img {
    margin-top: res(45px);
    width: res(282px);

    &>img {
      width: 100%;
    }

    &.-twoline {
      margin-top: res(10px);
    }
  }

  .model-list {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
  }

  .car-flex {
    display: flex !important;
    flex-direction: initial !important;
  }

  .model-item {
    width: 150px;
    height: 130px;

    &:nth-child(2n) {
      border-left: 2px solid #fff;
    }

    &:nth-child(n+3) {
      border-top: 2px solid #fff;
    }
  }

  .model-text {
    margin-top: res(28px);
    font-size: res(1.6rem);
  }


}
