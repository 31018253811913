// form
.form {

  // 共通パーツ
  input,
  textarea,
  select {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 0;
    font-size: 1.6rem;
    color: #4D4D4D;
  }

  input[type="text"],
  textarea {
    width: 100%;
    letter-spacing: track(50);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  input[type="text"],
  select {
    width: 100%;
    height: 38px;
  }

  ::-webkit-input-placeholder {
    color: #ccc;

    @include media(pc-s) {
      font-size: res(1.6rem);
    }

    @include media(tb) {
      font-size: 1.6rem;
    }
  }

  :-ms-input-placeholder {
    color: #ccc;

    @include media(pc-s) {
      font-size: res(1.6rem);
    }

    @include media(tb) {
      font-size: 1.6rem;
    }
  }

  ::placeholder {
    color: #ccc;

    @include media(pc-s) {
      font-size: res(1.6rem);
    }

    @include media(tb) {
      font-size: 1.6rem;
    }
  }


  &__block {
    margin-top: 30px;
    padding-top: 80px;
    border-top: 1px solid $color-bk;

    @include media(pc-s) {
      margin-top: res(30px);
      padding-top: res(80px);
    }

    @include media(sp) {
      margin-top: 14px;
      padding-top: 30px;
    }

    &:first-child {
      margin-top: 0;
      padding-top: 0;
      border-top: none;
    }
  }

  &__inner {
    padding: 0 118px;

    @include media(pc-s) {
      padding: 0 res(118px);
    }

    @include media(tb) {
      padding: 0;
    }
  }


  // 御見積フォーム用
  &__category {
    max-width: 300px;
    width: 100%;
    background-color: $color-sub02;
    margin: 0 auto;
    padding: 6px 0;
    border-radius: 10em;
    font-size: 2rem;
    letter-spacing: track(100);
    font-weight: 500;
    text-align: center;
    color: $color-wh;

    @include media(pc-s) {
      max-width: res(300px);
      padding: res(6px) 0;
      font-size: res(2rem);
    }

    @include media(sp) {
      max-width: 250px;
      padding: 7px 0;
      font-size: 1.8rem;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    padding: 40px 42px;
    border-bottom: 1px dotted #999899;

    @include media(pc-s) {
      padding: res(40px) res(42px);
    }

    @include media(sp) {
      flex-direction: column;
      align-items: flex-start;
      padding: 22px 16px;
    }

    &:first-of-type {
      margin-top: 20px;

      @include media(pc-s) {
        margin-top: res(20px);
      }

      @include media(sp) {
        margin-top: 0;
      }
    }

    &:last-of-type {
      border-bottom: none;
    }

    &.-flexstart,
    &.-plan {
      align-items: flex-start;
    }

    &.-borderT {
      margin-top: 8px;
      border-top: 1px dotted #999899;

      @include media(pc-s) {
        margin-top: res(8px);
      }

      @include media(sp) {
        margin-top: 6px;
      }
    }
  }

  &__title {
    width: 33%;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: track(50);
    line-height: 1.3;
    color: #4D4D4D;

    @include media(pc-s) {
      font-size: res(1.6rem);
    }

    @include media(sp) {
      width: 100%;
      font-size: 1.3rem;
    }

    .red {
      font-size: 1rem;
      color: #E71F19;
      vertical-align: text-top;
    }
  }

  &__box {
    width: 67%;

    @include media(sp) {
      width: 100%;
      margin-top: 11px;

      // name用
      &.-name {
        margin-top: 28px;
      }
    }

    // selectタグ
    .select {
      display: flex;
      align-items: center;

      &__item {
        position: relative;
        display: flex;
        align-items: center;
        margin-right: 20px;

        @include media(pc-s) {
          margin-right: res(20px);
        }

        @include media(sp) {
          margin-right: 18px;
        }

        &:last-child {
          margin-right: 0;
        }

        &>select {
          appearance: none;
          width: 84px;
          background-color: #E6E6E5;
          text-align: center;

          @include media(pc-s) {
            width: res(84px);
          }

          @include media(sp) {
            width: 74px;
          }
        }

        &>p {
          margin-left: 5px;
          font-size: 1.4rem;
          font-weight: 400;

          @include media(pc-s) {
            margin-left: res(5px);
            font-size: res(1.4rem);
          }

          @include media(sp) {
            margin-left: 4px;
            font-size: 1.3rem;
          }
        }
      }
    }

    // radioタグ
    .radio {

      &__item {

        margin-top: 14px;

        @include media(pc-s) {
          margin-top: res(14px);
        }

        @include media(sp) {
          margin-top: 15px;
        }

        &:first-child {
          margin-top: 0;
        }

        input[type="radio"] {
          display: block;
          transform: scale(1.3);

          @include media(sp) {
            transform: scale(1.1);
          }
        }

        .mwform-radio-field {

          &>label {
            display: flex;
            align-items: center;
          }

          .mwform-radio-field-text {
            display: block;
            margin-left: 4px;
            font-size: 1.6rem;
            color: #4D4D4D;

            @include media(pc-s) {
              margin-left: res(4px);
              font-size: res(1.6rem);
            }

            @include media(sp) {
              margin-left: 8px;
              font-size: 1.4rem;
            }
          }
        }
      }

      .radioArea {
        margin-top: 23px;

        @include media(pc-s) {
          margin-top: res(23px);
        }

        @include media(sp) {
          margin-top: 22px;
        }

        &__text {
          font-size: 1.6rem;
          color: #231815;
          letter-spacing: track(100);

          @include media(pc-s) {
            font-size: res(1.6rem);
          }

          @include media(sp) {
            font-size: 1.3rem;
          }
        }

        &__input {
          position: relative;
          width: 100%;
          margin-top: 10px;

          @include media(pc-s) {
            margin-top: res(10px);
          }

          @include media(sp) {
            margin-top: 10px;
          }
        }
      }

      // 回答方法用CSS
      &.-method {
        display: flex;
        align-items: center;

        .radio__item {
          margin-top: 0;
          margin-left: 43px;

          @include media(pc-s) {
            margin-left: res(43px);
          }

          @include media(sp) {
            margin-left: 43px;
          }

          &:first-child {
            margin-left: 0;
          }
        }
      }
    }

    // textarea
    .textarea {
      position: relative;

      textarea {
        width: 100%;
        line-height: line(25, 16);
      }
    }
  }


  // お問い合わせ用CSS
  &__list {
    margin-top: 58px;
    padding: 0 42px 40px;
    border-bottom: 1px dotted #999899;

    @include media(pc-s) {
      margin-top: res(58px);
      padding: 0 res(42px) res(40px);
    }

    @include media(sp) {
      margin-top: 22px;
      padding: 0 16px 26px;
    }

    &:last-child {
      border-bottom: none;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    margin-top: 34px;

    @include media(pc-s) {
      margin-top: res(34px);
    }

    @include media(sp) {
      flex-direction: column;
      align-items: flex-start;
      margin-top: 24px;
    }

    &:first-child {
      margin-top: 0;
    }

    &.-flexstart {
      align-items: flex-start;
    }

    // input
    .input {
      position: relative;

      input {
        width: 100%;
      }

      p {
        padding-right: 6px;
        font-size: 1.4rem;

        @include media(pc-s) {
          padding-right: res(6px);
          font-size: res(1.4rem);
        }

        @include media(sp) {
          font-size: 1.3rem;
          padding-right: 0;
        }

        &.-first {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translate(-100%, -50%);

          @include media(sp) {
            top: 0;
            padding-bottom: 6px;
            transform: translate(0, -100%);
          }
        }
      }
    }

    // input(name)
    .name {
      display: flex;
      align-items: center;

      @include media(sp) {
        flex-direction: column;
        align-items: flex-start;
      }

      &__item {
        position: relative;
        display: flex;
        align-items: center;

        @include media(sp) {
          flex-direction: column;
          align-items: flex-start;
          width: 100%;

          &:first-child {

            span.error {
              padding-left: 9%;
            }
          }
        }

        &:last-child {
          margin-left: auto;

          @include media(sp) {
            margin-top: 10px;
            margin-left: 0;
          }
        }

        p {
          padding-right: 6px;
          font-size: 1.4rem;

          @include media(pc-s) {
            padding-right: res(6px);
            font-size: res(1.4rem);
          }

          @include media(sp) {
            padding-left: 0;
            padding-bottom: 6px;
            font-size: 1.3rem;
          }

          &.-first {
            position: absolute;
            left: 0;
            transform: translateX(-100%);

            @include media(sp) {
              top: 0;
              transform: translate(0, -100%);
            }
          }
        }

        input {
          width: 188px;

          @include media(pc-s) {
            width: res(188px);
          }

          @include media(tb) {
            width: 175px;
          }

          @include media(sp) {
            width: 100%;
          }
        }
      }
    }

    // input(tel)
    .tel {
      display: flex;
      align-items: center;
      margin-left: -14px;

      @include media(pc-s) {
        margin-left: res(-14px);
      }

      @include media(sp) {
        margin-left: -9px;
      }

      &__item {
        position: relative;
        width: calc(33.333% - 14px);
        margin-left: 14px;

        @include media(pc-s) {
          width: calc(33.333% - #{res(14px)});
          margin-left: res(14px);
        }

        @include media(sp) {
          width: calc(33.333% - 9px);
          margin-left: 9px;
        }

        &.-flexstart {
          align-items: flex-start;
        }

        &::before {
          content: "-";
          position: absolute;
          top: 50%;
          left: -4px;
          transform: translate(-100%, -50%);
          font-size: 1.6rem;
          font-weight: 500;
          color: #ccc;

          @include media(pc-s) {
            left: -3px;
            font-size: res(1.6rem);
          }

          @include media(sp) {
            left: -2px;
            font-size: 1.2rem;
          }
        }

        &:first-child::before {
          display: none;
        }
      }
    }
  }


  // 備考用
  .formArea {
    padding: 40px 42px 40px;

    @include media(pc-s) {
      padding: res(40px) res(42px);
    }

    @include media(sp) {
      padding: 18px 16px;
    }

    &__txt {
      font-size: 1.5rem;
      letter-spacing: track(100);
      color: #231815;

      @include media(pc-s) {
        font-size: res(1.5rem);
      }

      @include media(sp) {
        font-size: 1.3rem;
        line-height: line(23, 13);
      }
    }

    &__container {
      display: flex;
      margin-top: 33px;

      @include media(pc-s) {
        margin-top: res(33px);
      }

      @include media(sp) {
        flex-direction: column;
        margin-top: 16px;
      }
    }
  }


  // submitBtn
  .formBtn {
    margin-top: 90px;

    @include media(pc-s) {
      margin-top: res(90px);
    }

    @include media(sp) {
      margin-top: 45px;
    }

    &__inner {
      display: flex;
      justify-content: center;
    }

    &__btn {
      position: relative;
      max-width: 420px;
      width: 100%;
      transition: opacity .2s;

      &.-backBtn {
        display: none;
      }

      &::before {
        @include arrow;
        right: 5.5%;
        display: block;
        width: 14px;
        height: 14px;
        border-top: solid 1px $color-wh;
        border-right: solid 1px $color-wh;
      }

      @include media(pc-s) {
        max-width: res(420px);
      }

      &:hover {
        opacity: .8;
      }

      .submitBtn {
        display: block;
        width: 100%;
        background-color: $color-main;
        padding: 24px 0;
        border: 2px solid $color-main;
        border-radius: .5em;
        font-size: 2.1rem;
        font-weight: 500;
        letter-spacing: track(100);
        color: $color-wh;
        cursor: pointer;

        @include media(pc-s) {
          padding: res(24px) 0;
          font-size: res(2.1rem);
        }

        @include media(sp) {
          padding: 20px 0;
          font-size: 2rem;
        }
      }
    }
  }


  // エラー関係
  span.error {
    position: absolute;
    left: 0;
    bottom: -3px;
    transform: translateY(100%);
    font-size: 1.1rem;
    color: #E71F19;

    @include media(pc-s) {
      font-size: 1rem;
    }
  }

  // input(name)
  .name {

    &__item {

      @include media(sp) {
        &:first-child {
          span.error {
            padding-left: 9%;
          }
        }
      }

      &.-error01 {
        .error {
          left: 1.5em;

          @include media(sp) {
            left: 0;
          }
        }
      }

      &.-error02 {
        .error {
          left: 2.5em;

          @include media(sp) {
            left: 0;
          }
        }
      }
    }
  }
}
