/* ---------------------------------------
　 ハイヤーサービス共通
--------------------------------------- */
.service {
  font-weight: 400;
}

.service__read {
  padding: 100px 0;
  font-size: 1.9rem;
  letter-spacing: track(100);
  line-height: 2.105263158;
  line-height: line(40, 19);
  text-align: center;

  @include media(pc-s) {
    padding: res(100px) 0;
    font-size: res(1.9rem);
  }

  @include media(sp) {
    padding: 50px 0;
    font-size: 1.5rem;
    line-height: line(31, 15);
  }
}

.service__note {
  margin-top: 40px;
  font-size: 1.4rem;
  letter-spacing: track(50);
  line-height: line(22, 14);
  color: #040000;
  text-align: center;

  @include media(pc-s) {
    margin-top: res(40px);
    font-size: res(1.4rem);
  }

  @include media(sp) {
    margin-top: 32px;
    font-size: 1.2rem;
    line-height: line(22, 12);
  }
}

/* ---------------------------------------
　 ハイヤー料金のしくみ
--------------------------------------- */
.serviceprice {
  padding: 50px 0 70px;
  border-top: 1px solid #040000;
  border-bottom: 1px solid #040000;

  @include media(pc-s) {
    padding: res(50px) 0 res(70px);
  }

  @include media(sp) {
    padding-top: 34px;
    padding-bottom: 42px;
  }
}

.serviceprice__title {
  font-size: 2.8rem;
  letter-spacing: track(100);
  font-weight: 500;
  text-align: center;

  @include media(pc-s) {
    font-size: res(2.8rem);
  }

  @include media(sp) {
    font-size: 2.2rem;
  }
}

.serviceprice__text {
  margin-top: 20px;
  font-size: 1.5rem;
  letter-spacing: track(100);
  text-align: center;

  @include media(pc-s) {
    margin-top: res(20px);
    font-size: res(1.5rem);
  }

  @include media(sp) {
    margin-top: 20px;
    font-size: 1.4rem;
    line-height: line(25, 14);
  }
}

.serviceprice__img {
  max-width: 758px;
  margin: 40px auto 0;
  text-align: center;

  @include media(pc-s) {
    max-width: res(758px);
    margin-top: res(40px);
  }

  @include media(sp) {
    max-width: 218px;
    margin-top: 30px;
  }

  img {
    width: 100%;
  }
}

.servicepriceTable {
  margin-top: 54px;

  @include media(pc-s) {
    margin-top: res(54px);
  }

  @include media(sp) {
    margin-top: 46px;
  }
}

.servicepriceTable__title {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: track(100);
  color: $color-wh;
  text-align: center;

  @include media(pc-s) {
    font-size: res(2rem);
  }

  @include media(sp) {
    font-size: 1.8rem;
  }

  & > span {
    background-color: $color-sub02;
    padding: 7px 30px 8px;
    border-radius: 10em;

    @include media(pc-s) {
      padding: res(7px) res(30px) res(8px);
    }

    @include media(sp) {
      padding: 7px 26px 8px;
    }
  }
}

.servicepriceTable__table {
  max-width: 835px;
  margin: 14px auto 0;
  font-size: 1.6rem;

  @include media(pc-s) {
    max-width: res(835px);
    margin-top: res(14px);
    font-size: res(1.6rem);
  }

  @include media(sp) {
    margin-top: 28px;
    font-size: 1.4rem;
  }

  & > caption {
    padding-bottom: 6px;
    text-align: right;

    @include media(pc-s) {
      padding-bottom: res(6px);
    }

    @include media(sp) {
      padding-bottom: 4px;
    }
  }

  tr {
    th,
    td {
      padding: 12px 0;
      letter-spacing: track(100);
      font-weight: 500;
      text-align: center;

      @include media(pc-s) {
        padding: res(12px) 0;
      }

      @include media(sp) {
        padding: 12px 0;

        &.--small {
          font-size: 1.1rem;
        }
      }

      &:last-child {
        border-right: 0;
      }
    }

    th {
      background-color: $color-main;
      border-right: 1px solid $color-wh;
      color: $color-wh;
      line-height: 1.3;

      &:nth-child(2){
        width: 65%;
      }

      // &:nth-child(2),
      // &:nth-child(3) {
      //   width: 36%;
      // }

      & .txtNone{
        @include media(sp){
          display: none;
        }
      }
    }

    td {
      background-color: $color-bg;
      border-right: 1px dotted $color-main;
      border-bottom: 1px solid $color-main;

      &:nth-child(n + 2) {
        font-weight: 400;
      }
    }

    &:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}

/* ---------------------------------------
　 プラン共通
--------------------------------------- */
.plan {
  padding: 98px 0 110px;
  text-align: center;

  @include media(pc-s) {
    padding: res(98px) 0 res(110px);
  }

  @include media(sp) {
    padding: 50px 0 56px;
  }

  &.--airport {
    padding-bottom: 0;
    border-top: 1px solid #040000;
  }
}

.plan__title {
  position: relative;
  font-size: 5.2rem;
  font-weight: 500;
  letter-spacing: track(100);
  color: $color-main;
  text-align: center;
  z-index: 2;

  @include media(pc-s) {
    font-size: res(5.2rem);
  }

  @include media(sp) {
    font-size: 3rem;
  }
}

.plan__img {
  margin-top: 42px;

  @include media(pc-s) {
    margin-top: res(42px);
  }

  @include media(sp) {
    width: 100vw;
    margin-top: 22px;
    margin-left: -15px;
    margin-right: -15px;
  }

  img {
    max-width: 835px;
    width: 100%;

    @include media(pc-s) {
      max-width: res(835px);
    }

    @include media(sp) {
      max-width: none;
      width: 100%;
    }
  }
}

.plan__text {
  margin-top: 50px;
  font-size: 1.9rem;
  letter-spacing: track(100);
  line-height: line(31, 19);
  color: $color-text;

  @include media(pc-s) {
    margin-top: res(50px);
    font-size: res(1.9rem);
  }

  @include media(sp) {
    margin-top: 38px;
    font-size: 1.5rem;
    line-height: line(31, 15);
  }
}

.plan__block {
  margin-top: 50px;

  @include media(pc-s) {
    margin-top: res(50px);
  }

  @include media(sp) {
    margin-top: 36px;
  }
}

.plan__subtitle {
  display: inline-block;
  padding: 0 12px 10px;
  border-bottom: 1px solid #040000;
  font-size: 2.8rem;
  font-weight: 500;
  color: $color-text;
  letter-spacing: track(100);

  @include media(pc-s) {
    padding: 0 res(12px) res(10px);
    font-size: res(2.8rem);
  }

  @include media(sp) {
    display: inline;
    padding: 0 3px 6px;
    border-bottom: 0;
    font-size: 1.8rem;
    line-height: line(34, 18);
    background: linear-gradient(transparent 97%, #040000 1%);
  }
}

.plan__button {
  position: relative;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-top: 40px;
  color: $color-main;
  background-color: #fbd06f;
  border-radius: 15px;
  transition: opacity 0.2s;

  @include media(pc-s) {
    max-width: res(420px);
    margin-top: res(40px);
  }

  @include media(sp) {
    max-width: 360px;
    margin-top: 26px;
    border-radius: 0.8em;
  }

  &:hover {
    opacity: 0.7;
    transition: opacity 0.2s;
  }

  a {
    display: block;
    font-size: 2.4rem;
    letter-spacing: track(100);
    font-weight: 500;
    padding: 34px 0;

    @include media(pc-s) {
      font-size: res(2.4rem);
      padding: res(34px) 0;
    }

    @include media(sp) {
      font-size: 2rem;
      padding: 22px 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 7%;
    transform: translateY(-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    border-top: solid 2px #002b51;
    border-right: solid 2px #002b51;

    @include media(pc-s) {
      width: res(15px);
      height: res(15px);
    }

    @include media(tb) {
      width: 12px;
      height: 12px;
      border-top: solid 1px #002b51;
      border-right: solid 1px #002b51;
    }

    @include media(sp) {
      right: 8%;
    }
  }
}

/* ---------------------------------------
　 プリープラン
--------------------------------------- */
.planCourse {
  margin-top: 60px;

  @include media(pc-s) {
    margin-top: res(60px);
  }

  @include media(sp) {
    margin-top: 50px;
  }
}

.planCourse__title {
  font-size: 2.8rem;
  font-weight: 500;
  letter-spacing: track(100);

  @include media(pc-s) {
    font-size: res(2.8rem);
  }

  @include media(sp) {
    margin-bottom: 20px;
    font-size: 2.2rem;
  }
}

.planCourse__subtitle {
  display: inline-block;
  margin-top: 40px;
  padding: 0 12px 10px;
  border-bottom: 1px solid #040000;
  font-size: 3.2rem;
  font-weight: 500;
  letter-spacing: track(100);

  @include media(pc-s) {
    margin-top: res(40px);
    padding: 0 res(12px) res(10px);
    font-size: res(3.2rem);
  }

  @include media(sp) {
    display: inline;
    margin-top: 0;
    padding: 0 3px 6px;
    border-bottom: 0;
    font-size: 1.8rem;
    line-height: line(34, 18);
    background: linear-gradient(transparent 97%, #040000 1%);
  }
}

.planCourseBox {
  margin-top: 50px;

  @include media(pc-s) {
    margin-top: res(50px);
  }

  @include media(sp) {
    margin-top: 28px;
  }
}

.planCourseBox__item {
  margin-top: 40px;

  @include media(pc-s) {
    margin-top: res(40px);
  }

  @include media(sp) {
    margin-top: 52px;
  }

  &:first-child {
    margin-top: 0;
  }

  .card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 915px;
    width: 100%;
    margin: 0 auto;

    @include media(pc-s) {
      max-width: res(915px);
    }

    @include media(sp) {
      flex-direction: column;
    }
  }

  .card__img {
    max-width: 360px;
    width: 100%;
    margin-right: 55px;

    @include media(pc-s) {
      max-width: res(360px);
      margin-right: res(55px);
    }

    @include media(sp) {
      max-width: 400px;
      margin-right: 0;
    }
  }

  .cardBox {
    flex: 1;
  }

  .cardBox__title {
    padding-bottom: 14px;
    border-bottom: 1px solid #e5e5e4;
    font-size: 2.8rem;
    font-weight: 500;
    letter-spacing: track(100);
    line-height: line(39, 28);

    @include media(pc-s) {
      padding-bottom: res(14px);
      font-size: res(2.8rem);
    }

    @include media(sp) {
      margin-top: 16px;
      padding-bottom: 14px;
      font-size: 1.8rem;
      line-height: line(26, 18);
    }
  }

  .cardBoxTextbox {
    margin-top: 18px;

    @include media(pc-s) {
      margin-top: res(18px);
    }

    @include media(sp) {
      margin-top: 18px;
    }

    & + .cardBoxTextbox {
      margin-top: 36px;

      @include media(pc-s) {
        margin-top: res(36px);
      }

      @include media(sp) {
        margin-top: 26px;
      }
    }
  }

  .cardBoxTextbox__text {
    margin-top: 10px;
    font-size: 1.7rem;
    letter-spacing: track(50);
    line-height: line(26, 17);
    color: $color-text;

    @include media(pc-s) {
      margin-top: res(10px);
      font-size: res(1.7rem);
    }

    @include media(sp) {
      font-size: 1.3rem;
    }

    span {
      position: relative;
      padding: 0 16px;

      @include media(pc-s) {
        padding: 0 res(16px);
      }

      @include media(sp) {
        padding: 0 11px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 15px;
        height: 12px;
        top: 6px;
        left: 10px;
        background-image: url(../images/service/arrow@2x.png);
        background-size: contain;
        background-position: 0 0;
        background-repeat: no-repeat;

        @include media(pc-s) {
          width: res(15px);
          height: res(12px);
          left: res(10px);
        }

        @include media(sp) {
          width: 12px;
          height: 10px;
          top: 6px;
          left: 8px;
        }
      }
    }
  }

  .cardBoxTextboxFlex {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      width: 100%;
      background-image: linear-gradient(to right, #000, #000 1px, transparent 1px, transparent 3px);
      background-size: 3px 1px;
      border-radius: 3em;
      background-repeat: repeat-x;
      margin-top: 13px;

      @include media(pc-s) {
        margin-top: res(13px);
      }

      @include media(sp) {
        margin-top: 11px;
      }
    }
  }

  .cardBoxTextboxFlex__title {
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding-right: 12px;
    font-size: 2.3rem;
    letter-spacing: track(50);

    @include media(pc-s) {
      padding-right: res(12px);
      font-size: res(2.3rem);
    }

    @include media(sp) {
      padding-right: 10px;
      font-size: 1.6rem;
    }
  }

  .cardBoxTextboxFlex__text {
    position: relative;
    z-index: 2;
    background-color: #fff;
    padding-left: 12px;
    margin-left: 102px;
    font-size: 2.8rem;
    letter-spacing: track(50);

    @include media(pc-s) {
      padding-left: res(12px);
      margin-left: res(102px);
      font-size: res(2.8rem);
    }

    @include media(sp) {
      padding-left: 10px;
      margin-left: 66px;
      font-size: 2.2rem;
    }
  }
}

/* ---------------------------------------
　 空港送迎プラン
--------------------------------------- */
.airport__text {
  margin-top: 51px;
  font-size: 1.4rem;
  letter-spacing: track(50);
  line-height: 1.571428571;

  @include media(pc-s) {
    margin-top: res(51px);
    font-size: res(1.4rem);
  }

  @include media(sp) {
    margin-top: 34px;
    font-size: 1.2rem;
    line-height: 1.833333333;
  }
}

.airport__list {
  margin-top: 51px;

  @include media(pc-s) {
    margin-top: res(51px);
  }

  @include media(sp) {
  }
}

.airport__item {
  margin-top: 36px;

  @include media(pc-s) {
    margin-top: res(36px);
  }

  @include media(sp) {
    padding-bottom: 28px;
    border-bottom: 1px dotted #979697;
  }

  &:first-child {
    margin-top: 0;
  }

  .card {
    max-width: 758px;
    width: 100%;
    margin: 0 auto;
    color: $color-text;

    @include media(pc-s) {
      max-width: res(758px);
    }

    @include media(sp) {
      position: relative;
      max-width: 300px;
      width: 100%;
      margin: 0 auto;
    }
  }

  .cardFlex {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    width: 100%;
    text-align: left;

    @include media(sp) {
      flex-direction: column;
    }

    &::before {
      position: absolute;
      content: "";
      background-image: linear-gradient(to right, #000, #000 1px, transparent 1px, transparent 3px);
      background-size: 3px 1px;
      border-radius: 3em;
      background-repeat: repeat-x;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin-top: 18px;

      @include media(pc-s) {
        margin-top: res(13px);
      }

      @include media(sp) {
        content: none;
      }
    }
  }

  .cardFlex__title {
    padding-right: 10px;
    font-size: 2rem;
    letter-spacing: track(50);
    background-color: #fff;
    position: relative;
    z-index: 2;
    margin-right: auto;

    @include media(pc-s) {
      padding-right: res(10px);
      font-size: res(2rem);
    }

    @include media(sp) {
      font-size: 2rem;
      font-weight: 500;
      line-height: 1.4;
      // padding-left: 1em;
      // text-indent: -1em;
    }
  }

  .cardFlex__text {
    padding-left: 10px;
    font-size: 2.8rem;
    letter-spacing: track(50);
    background-color: #fff;
    position: relative;
    z-index: 2;

    @include media(pc-s) {
      padding-left: res(10px);
      font-size: res(2.8rem);
    }

    @include media(sp) {
      margin-top: 68px;
      padding-left: 0;
      font-size: 2.2rem;
    }

    span {
      font-size: 2rem;
      letter-spacing: track(20);

      @include media(pc-s) {
        font-size: res(2rem);
      }

      @include media(sp) {
        font-size: 1.3rem;
        letter-spacing: track(100);
      }
    }
  }

  .card__text {
    width: 100%;
    margin-top: 10px;
    text-align: left;
    font-size: 1.4rem;
    letter-spacing: track(20);

    @include media(pc-s) {
      margin-top: res(10px);
      font-size: res(1.4rem);
    }

    @include media(sp) {
      position: absolute;
      top: 64px;
      margin-top: 0;
      font-size: 1.3rem;
      line-height: 1.615384615;
    }
  }
}

.serviceContact {
  margin-top: 112px;
  text-align: center;

  @include media(pc-s) {
    margin-top: res(112px);
  }

  @include media(sp) {
    margin-top: 56px;
  }
}

.serviceContact__text {
  font-size: 2.8rem;
  letter-spacing: track(100);
  font-weight: 400;

  @include media(pc-s) {
    font-size: res(2.8rem);
  }

  @include media(sp) {
    font-size: 1.5rem;
    line-height: 1.733333333;
  }
}

.serviceContact__button {
  position: relative;
  max-width: 420px;
  width: 100%;
  margin: 0 auto;
  margin-top: 44px;
  color: $color-wh;
  background-color: $color-main;
  border-radius: 15px;
  transition: opacity 0.2s;

  @include media(pc-s) {
    max-width: res(420px);
    margin-top: res(44px);
  }

  @include media(sp) {
    margin-top: 26px;
  }

  &:hover {
    opacity: 0.7;
    transition: opacity 0.2s;
  }

  a {
    display: block;
    padding: 34px 0;
    font-size: 2.4rem;
    letter-spacing: track(100);
    font-weight: 500;

    @include media(pc-s) {
      padding: res(34px) 0;
      font-size: res(2.4rem);
    }

    @include media(sp) {
      padding: 22px 0;
    }
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    top: 50%;
    right: 7%;
    transform: translateY(-50%) rotate(45deg);
    width: 15px;
    height: 15px;
    border-top: solid 2px $color-wh;
    border-right: solid 2px $color-wh;

    @include media(pc-s) {
      width: res(15px);
      height: res(15px);
    }

    @include media(tb) {
      width: 12px;
      height: 12px;
      border-top: solid 1px $color-wh;
      border-right: solid 1px $color-wh;
    }

    @include media(sp) {
      right: 8%;
    }
  }
}
