.flowButtonarea {
  display: flex;
  margin-top: 110px;

  @include media(pc-s) {
    margin-top: res(110px);
  }

  @include media(sp) {
    flex-direction: column;
    align-items: center;
    margin-top: 50px;
  }


  &__button {
    position: relative;
    max-width: 478px;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    border-radius: 13px;
    transition: opacity .2s;

    @include media(pc-s) {
      max-width: res(478px);
    }

    @include media(sp) {
      max-width: 330px;
    }


    &:hover {
      opacity: .7;
      transition: opacity .2s;
    }

    a {
      display: block;
      font-size: 2.4rem;
      letter-spacing: track(100);
      font-weight: 500;
      padding: 32px 0;

      @include media(pc-s) {
        font-size: res(2.4rem);
        padding: res(32px) 0;
      }

      @include media(sp) {
        font-size: 2rem;
        padding: 23px 0;
      }
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 50%;
      right: 7%;
      transform: translateY(-50%) rotate(45deg);
      width: 15px;
      height: 15px;

      @include media(pc-s) {
        width: res(15px);
        height: res(15px);
      }

      @include media(tb) {
        width: 12px;
        height: 12px;
      }

      @include media(sp) {
        right: 8%;
      }
    }


    &.-higher {
      color: $color-main;
      background-color: #FBD06F;

      &::before {
        border-top: solid 2px #002B51;
        border-right: solid 2px #002B51;

        @include media(tb) {
          border-top: solid 1px #002B51;
          border-right: solid 1px #002B51;
        }
      }
    }

    &.-contact {
      color: $color-wh;
      background-color: $color-main;
      margin-left: 44px;

      @include media(pc-s) {
        margin-left: res(44px);
      }

      @include media(sp) {
        margin-top: 16px;
        margin-left: auto;
      }


      &::before {
        border-top: solid 2px $color-wh;
        border-right: solid 2px $color-wh;

        @include media(tb) {
          border-top: solid 1px $color-wh;
          border-right: solid 1px $color-wh;
        }
      }
    }
  }
}


.flowArea {
  padding: 0 117px;

  @include media(pc-s) {
    padding: 0 res(117px);
  }

  @include media(tb) {
    padding: 0;
  }

  .flowArea-tel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;

    @include media(pc-s) {
      margin-top: res(40px);
    }

    @include media(sp) {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 23px;
    }

    &__num {
      font-size: 3rem;
      font-weight: 600;
      font-family: $font-en;
      letter-spacing: track(10);

      @include media(pc-s) {
        font-size: res(3rem);
      }

      @include media(sp) {
        font-size: 2.5rem;
      }

      &>a {
        display: block;
      }
    }

    &__txt {
      margin-left: 10px;
      font-size: 1.4rem;
      font-weight: 500;

      @include media(pc-s) {
        margin-left: res(10px);
        font-size: res(1.4rem);
      }

      @include media(sp) {
        margin-top: 8px;
        margin-left: 0;
        font-size: 1.4rem;
      }
    }
  }

  &__text {
    margin-top: 80px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: track(100);
    line-height: 2.066666667;

    @include media(pc-s) {
      margin-top: res(80px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      margin-top: 41px;
      font-size: 1.5rem;
      letter-spacing: track(50);
    }
  }
}

.flowArea__mail{
  margin-top: 20px;
  text-align: center;
  font-size: 2.8rem;
  font-weight: 600;
  font-family: $font-en;
  letter-spacing: track(10);

  @include media(pc-s) {
    margin-top: res(20px);
    font-size: res(2.8rem);
  }

  @include media(sp) {
    margin-top: 15px;
    font-size: 2rem;
  }

  &>a {
    display: block;
  }
}

.flowAreaBox {
  padding-top: 44px;
  padding-bottom: 36px;
  padding-left: 10px;
  border-bottom: 1px dotted #989798;

  @include media(pc-s) {
    padding-top: res(44px);
    padding-bottom: res(36px);
    padding-left: res(10px);
  }

  @include media(sp) {
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 0;
  }

  &:first-of-type {
    padding-top: 82px;

    @include media(pc-s) {
      padding-top: res(82px);
    }

    @include media(sp) {
      padding-top: 38px;
    }
  }

  &:last-child {
    padding-bottom: 0;
    border-bottom: none;
  }

  &__title {
    position: relative;
    font-size: 2.5rem;
    padding-left: 43px;
    letter-spacing: track(50);
    font-weight: 600;
    color: #4D4D4D;

    @include media(pc-s) {
      padding-left: res(43px);
      font-size: res(2.5rem);
    }

    @include media(sp) {
      padding-left: 34px;
      font-size: 1.9rem;
      line-height: 1.315789474;
    }

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 33px;
      height: 33px;
      background-size: contain;
      background-position: 0 0;
      background-repeat: no-repeat;

      @include media(pc-s) {
        width: res(33px);
        height: res(33px);
        font-size: res(1.6rem);
        padding-top: res(9px);
      }

      @include media(sp) {
        width: 25px;
        height: 25px;
        padding-top: 5px;
        padding-left: 1px;
        font-size: 1.4rem;
      }
    }

    &.-num01 {
      &::before {
        background-image: url(../images/flow/01.svg);

        @include media(sp) {
          top: 29% !important;
        }
      }
    }

    &.-num02 {
      &::before {
        background-image: url(../images/flow/02.svg);
      }
    }

    &.-num03 {
      &::before {
        background-image: url(../images/flow/03.svg);

        // @include media(sp) {
        //   top: 30% !important;
        // }
      }
    }

    &.-num04 {
      &::before {
        background-image: url(../images/flow/04.svg);

        @include media(sp) {
          top: 29% !important;
        }
      }
    }

    &.-num05 {
      &::before {
        background-image: url(../images/flow/05.svg);
      }
    }

    &.-num06 {
      &::before {
        background-image: url(../images/flow/06.svg);
      }
    }

    &.-num07 {
      &::before {
        background-image: url(../images/flow/07.svg);
      }
    }
  }

  &__subtitle {
    display: inline-block;
    margin-top: 25px;
    padding: 8px 10px;
    font-size: 1.5rem;
    font-weight: 500;
    letter-spacing: track(100);
    color: $color-sub02;
    border: 1px solid $color-sub02;

    @include media(pc-s) {
      margin-top: res(25px);
      padding: res(8px) res(10px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      margin-top: 15px;
      font-size: 1.4rem;
      padding: 6px 8px;
    }

    span {
      color: #E61F19;
    }

    &.-tow {
      line-height: 1.357142857;
      padding: 5px 7px;

      @include media(pc-s) {
        padding: res(5px) res(7px);
      }

      @include media(sp) {
        padding: 6px 8px;
      }
    }
  }

  &__text {
    margin-top: 13px;
    font-size: 1.5rem;
    line-height: 1.866666667;
    letter-spacing: track(100);
    font-weight: 400;

    @include media(pc-s) {
      margin-top: res(13px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      margin-top: 6px;
      font-size: 1.4rem;
      letter-spacing: track(50);
    }

    &+.flowAreaBox__subtitle {
      margin-top: 24px;

      @include media(pc-s) {
        margin-top: res(24px);
      }

      @include media(sp) {
        margin-top: 16px;
      }
    }
  }

  span {
    color: #E61F19;
  }

  &.-ls75 {
    margin-top: 12px;
    letter-spacing: track(75);

    @include media(pc-s) {
      margin-top: res(12px);
    }

    @include media(sp) {
      margin-top: 4px;
      letter-spacing: track(50);
    }
  }
}


.flowNote {
  margin: 0 100px;
  margin-top: 74px;
  padding-top: 55px;
  padding-left: 24px;
  padding-bottom: 58px;
  border-top: 1px solid #040000;
  border-bottom: 1px solid #040000;

  @include media(pc-s) {
    margin: 0 res(100px);
    margin-top: res(74px);
    padding-top: res(55px);
    padding-left: res(24px);
    padding-bottom: res(58px);
  }

  @include media(sp) {
    margin: 0 auto;
    margin-top: 46px;
    padding-top: 34px;
    padding-left: 0;
    padding-bottom: 45px;
  }

  &__title {
    font-size: 2.5rem;
    letter-spacing: track(100);
    font-weight: 500;

    @include media(pc-s) {
      font-size: res(2.5rem);
    }

    @include media(sp) {
      text-align: center;
    }
  }

  &__subtitle {
    margin-top: 26px;
    font-size: 1.6rem;
    letter-spacing: track(100);
    font-weight: 500;

    @include media(pc-s) {
      margin-top: res(26px);
      font-size: res(1.6rem);
    }

    @include media(sp) {
      margin-top: 21px;
      font-size: 1.6rem;
    }
  }

  &__text {
    margin-top: 6px;
    font-size: 1.5rem;
    letter-spacing: track(100);
    line-height: 1.8;

    @include media(pc-s) {
      margin-top: res(6px);
      font-size: res(1.5rem);
    }

    @include media(sp) {
      font-size: 1.4rem;
    }
  }
}
