//@keyframesから定義するanimationの設定
@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}


/* パララックス用
----------------------------------------------- */

// 単独フェードイン
.js-one {
  transform: translateY(70px);
  opacity: 0;
  transition: transform 1.5s, opacity 1.5s;
  transition-delay: .1s;

  &.isOne {
    transform: translate(0);
    opacity: 1;
  }
}

// 複数フェードイン
.js-multi {

  &>.topAbout__title,
  &>.topAbout__text,
  &>.topMenu__title,
  .topMenu__item {
    transform: translateY(70px);
    opacity: 0;
    transition: transform 1.2s, opacity 1.2s;
  }

  &>.topAbout__text {
    transition-delay: .2s;
  }

  .topMenu__item {
    transition-delay: .3s;
  }

  &.isMulti {

    &>.topAbout__title,
    &>.topAbout__text,
    &>.topMenu__title,
    .topMenu__item {
      transform: translate(0);
      opacity: 1;
    }
  }
}
