.tabList {
  display: none;

  @include media(sp) {
    display: none;
  }

  &.show {
    display: block;
    animation: fadein;
    animation-duration: 1s;

    @include media(sp) {
      display: block;
    }
  }
}

.vehicle {
  .vehicleCarTab {
    width: 100%;
    background-color: #eeeeee;

    &__list {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      margin-top: 82px;
      padding: 64px 0;
      text-align: center;

      @include media(pc-s) {
        max-width: res(1000px);
        margin-top: res(82px);
        padding: res(64px) 0;
      }

      @include media(tb) {
        padding: res(64px) 20px;
      }

      @include media(sp) {
        flex-direction: column;
        margin-top: 48px;
        padding: 30px 0;
      }
    }

    &.-bottom {
      .vehicleCarTab__list {
        margin-top: 0 !important;
      }
    }

    &__item {
      max-width: 320px;
      width: 100%;
      background-color: #fff;
      padding: 30px 0;
      border: 3px solid $color-main;
      border-radius: 10px;
      font-size: 2.2rem;
      letter-spacing: track(30);
      font-weight: 500;
      color: $color-main;
      transition: opacity 0.2s;

      @include media(pc-s) {
        max-width: res(320px);
        padding: res(30px) 0;
        font-size: res(2.2rem);
      }

      @include media(tb) {
        max-width: 230px;
        font-size: 1.6rem;
      }

      @include media(sp) {
        max-width: 330px;
        width: 100%;
        margin: 16px auto 0;
        padding: 20px 0;
        font-size: 2rem;
        border: 2px solid $color-main;

        &:first-child {
          margin-top: 0;
        }
      }

      &.active {
        background-color: $color-main;
        color: #fff;
      }

      &:hover {
        cursor: pointer;
        cursor: hand;
        opacity: 0.8;
        transition: opacity 0.2s;

        @include media(sp) {
          opacity: 1;
        }
      }
    }
  }
}

.vehicleCar {
  margin-top: 106px;
  margin-bottom: 86px;

  @include media(pc-s) {
    margin-top: res(106px);
    margin-bottom: res(86px);
  }

  @include media(sp) {
    margin-top: 48px;
    margin-bottom: 56px;
  }

  &__img {
    margin: 0 auto;

    img {
      width: 100%;
    }

    &.-hiace {
      max-width: 720px;

      @include media(pc-s) {
        max-width: res(720px);
      }

      @include media(sp) {
        max-width: 270px;
      }
    }

    &.-alphard,
    &.-noah {
      max-width: 850px;

      @include media(pc-s) {
        max-width: res(850px);
      }

      @include media(sp) {
        max-width: 318px;
      }
    }
  }

  &__title {
    margin-top: 24px;
    font-size: 4.5rem;
    font-weight: 500;
    letter-spacing: track(100);
    text-align: center;

    @include media(pc-s) {
      margin-top: res(24px);
      font-size: res(4.5rem);
    }

    @include media(sp) {
      margin-top: 25px;
      font-size: 2.2rem;
    }
  }

  &__text {
    margin-top: 47px;
    font-size: 1.6rem;
    line-height: 2.4375;
    letter-spacing: track(100);
    text-align: center;

    @include media(pc-s) {
      margin-top: res(47px);
      font-size: res(1.6rem);
    }

    @include media(sp) {
      margin-top: 24px;
      font-size: 1.5rem;

      line-height: 2.066666667;
    }

    &.-mini {
      margin-top: 3px;
      font-size: 1.4rem;
      letter-spacing: track(50);

      @include media(pc-s) {
        margin-top: res(3px);
        font-size: res(1.4rem);
      }

      @include media(sp) {
        margin-top: 5px;
        font-size: 1.2rem;
      }
    }
  }
}

.vehicleDes {
  padding-top: 80px;
  border-top: 1px solid #000;

  @include media(pc-s) {
    padding-top: res(80px);
  }

  @include media(sp) {
    padding-top: 52px;
  }

  &__title {
    max-width: 300px;
    width: 100%;
    margin: 0 auto;
    padding: 5px 0;
    background-color: $color-sub02;
    font-size: 2rem;
    font-weight: 500;
    letter-spacing: track(100);
    color: #fff;
    text-align: center;
    border-radius: 6em;

    @include media(pc-s) {
      max-width: res(300px);
      padding: res(5px) 0;
      font-size: res(2rem);
    }

    @include media(sp) {
      max-width: 260px;
      padding: 6px 0;
      font-size: 1.8rem;
    }
  }

  &__text {
    margin-top: 44px;
    text-align: center;
    font-size: 2.7rem;
    letter-spacing: track(100);
    font-weight: 500;

    &.-last {
      margin-top: 70px;

      @include media(pc-s) {
        margin-top: res(70px);
      }

      @include media(sp) {
        margin-top: 50px;
      }
    }
  }

  &__img {
    margin-top: 34px;
    margin-bottom: 130px;
    text-align: center;

    @include media(pc-s) {
      margin-top: res(34px);
      margin-bottom: res(130px);
    }

    @include media(sp) {
      margin-top: 30px;
      margin-bottom: 80px;
    }

    img {
      max-width: 692px;
      width: 100%;

      @include media(pc-s) {
        max-width: res(692px);
      }

      @include media(sp) {
        width: 242px;
      }
    }

    &.-mb {
      margin-bottom: 0 !important;
    }
  }

  &.-car2 {
    .vehicleDes__img {
      margin-top: 10px;

      @include media(pc-s) {
        margin-top: res(10px);
      }

      @include media(sp) {
        margin-top: 24px;
      }
    }
  }
}

.vehicleDesTable {
  display: flex;
  align-items: center;
  max-width: 765px;
  width: 100%;
  margin: 0 auto;
  padding: 34px 0;
  border-bottom: 1px dotted #989798;

  @include media(pc-s) {
    max-width: res(765px);
    padding: res(34px) 0;
  }

  @include media(sp) {
    align-items: baseline;
    padding: 14px 0 18px;
  }

  &:first-of-type {
    margin-top: 72px;
    padding-top: 0;

    @include media(pc-s) {
      margin-top: res(72px);
    }

    @include media(sp) {
      margin-top: 22px;
      padding: 13px 0;
      padding-top: 0;
    }
  }

  &.-last {
    margin-bottom: 68px;
    border-bottom: none;

    @include media(pc-s) {
      margin-bottom: res(68px);
    }

    @include media(sp) {
      padding-bottom: 0;
      margin-bottom: 46px;
    }
  }

  &.-tow {
    padding: 30px 0;

    @include media(pc-s) {
      padding: res(30px) 0;
    }

    @include media(sp) {
    }

    .vehicleDesTable__text {
      line-height: 1.444444444;

      @include media(sp) {
        line-height: 1.5;
      }
    }
  }

  &.-one {
    @include media(sp) {
      padding: 18px 0;
    }
  }

  &__title {
    width: 172px;
    padding-left: 18px;
    font-size: 1.6rem;
    font-weight: 500;
    letter-spacing: track(20);
    color: #989798;
    line-height: 1.444444444;

    @include media(pc-s) {
      width: res(172px);
      padding-left: res(18px);
      font-size: res(1.6rem);
    }

    @include media(sp) {
      width: 108px;
      padding-left: 10px;
      font-size: 1.4rem;
      line-height: 1.5;
    }
  }

  &__text {
    font-size: 1.7rem;
    letter-spacing: track(50);
    line-height: 1.444444444;
    color: #000;

    @include media(pc-s) {
      font-size: res(1.7rem);
    }

    @include media(sp) {
      font-size: 1.5rem;
      line-height: 1.5;
    }
  }
}

.vehicleDesTextbox {
  display: flex;
  justify-content: center;

  @include media(pc-s) {
  }

  @include media(sp) {
    flex-direction: column;
  }

  &__text {
    margin-top: 42px;
    margin-bottom: 80px;
    font-size: 2.7rem;
    font-weight: 500;
    letter-spacing: track(100);

    @include media(pc-s) {
      margin-top: res(42px);
      margin-bottom: res(80px);
      font-size: res(2.7rem);
    }

    @include media(sp) {
      width: 268px;
      margin: 0 auto;
      margin-top: 24px;
      font-size: 2rem;
    }

    &.-last {
      margin-left: 66px;

      @include media(pc-s) {
        margin-left: res(66px);
      }

      @include media(sp) {
        margin-left: auto;
        margin-top: 18px;
        margin-bottom: 56px;
      }
    }

    &.-car2 {
      margin-left: 0 !important;

      @include media(pc-s) {
        margin-left: 0 !important;
      }
    }

    span {
      color: #989798;

      @include media(pc-s) {
      }

      @include media(sp) {
      }
    }
  }
}

//_____________________________スライド________________________________

.vehicleCarSlide {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  @include media(pc-s) {
    margin-top: res(48px);
  }

  @include media(sp) {
    flex-wrap: wrap;
    width: 100%;
    margin-top: 34px;
  }

  &__item {
    margin-left: 14px;
    width: 100%;

    img {
      max-width: none;
      width: 100%;
    }

    @include media(pc-s) {
      margin-left: res(14px);
    }

    @include media(sp) {
      width: calc(50% - 7px);

      &:nth-child(2n) {
        margin-left: 0;
      }

      &:nth-child(n + 1) {
        margin-top: 14px;
      }
    }

    &:first-child {
      margin-left: 0;

      @include media(sp) {
        width: 100%;
        margin-top: 0;
        text-align: center;

        img {
          width: 50%;
        }
      }
    }

    &:hover {
      cursor: zoom-in !important;
    }
  }
}

.lightbox {
  display: flex;
  flex-direction: column-reverse;
}

.lb-number {
  visibility: hidden;
}

.lb-close {
  margin-left: auto;
  width: 28px !important;
  height: 28px !important;
  margin-bottom: 12px;
  background-image: url(../images/vehicle/batu.png) !important;
  background-size: contain !important;
}

.lb-image {
  img {
    max-width: 800px !important;
    width: 100% !important;
  }
}

.lb-nav a.lb-prev {
  opacity: 1 !important;
  margin-left: 10px;
  background-image: url(../images/vehicle/arrow-l.png) !important;
}

.lb-nav a.lb-next {
  position: absolute;
  opacity: 1 !important;
  background-image: url(../images/vehicle/arrow-r.png) !important;
  background-position-x: 98% !important;
}
