// change2410(システム変更後削除)

.smartmover__change{
  margin: 0 auto;
  margin-top: 60px;
  width: 900px;
  max-width: 95%;

  @include media(pc-s) {
    margin-top: res(60px);
    width: res(900px);
  }

  @include media(sp) {
    margin-top: 30px;
    width: 100%;
    max-width: 500px;
  }

  &>a{
    display: block;
    width: 100%;
    height: 100%;

    &:hover{
      opacity: 0.8;
    }

    &>picture img{
      width: 100%;
    }
  }
}

.change2410{
  margin-top: 160px;

  @include media(pc-s) {
    margin-top: res(160px);
  }

  @include media(sp) {
    margin-top: 80px;
  }
}

.change2410__img{
  width: 100%;
  margin-top: 60px;

  @include media(pc-s) {
    margin-top: res(60px);
  }

  @include media(sp) {
    margin-top: 30px;
  }

  &:first-child{
    margin-top: 0;
  }

  &>img{
    display: block;
    width: 100%;
  }
}


