/* header
------------------------------------------------ */
.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 500;
  width: 100%;
  background-color: $color-wh;

  &__inner {
    display: flex;
    align-items: center;
    width: 100%;
    height: 120px;
    padding: 0 20px 0 24px;

    @include media(pc-s) {
      height: res(120px);
      padding: 0 res(20px) 0 res(24px);
    }

    @include media(tb) {
      height: 70px;
      padding-right: 20px;
    }

    @include media(sp) {
      height: 60px;
      padding: 0 18px 0 10px;
    }
  }

  .header-logo {
    & > a {
      position: relative;
      display: block;
      width: 380px;
      z-index: 1;

      @include media(pc-s) {
        width: res(380px);
      }

      @include media(sp) {
        width: 254px;
      }

      &::before {
        content: "";
        display: block;
        padding-top: percentage(50px / 380px);
      }

      & > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      &:hover {
        opacity: 0.6;
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  // gnav
  .gnav {
    margin-right: 26px;

    @include media(pc-s) {
      margin-right: res(26px);
    }

    @include media(tb) {
      display: none;
    }
  }

  .gnav__list {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    &:nth-child(2) {
      margin-top: 22px;

      @include media(pc-s) {
        margin-top: res(22px);
      }
    }
  }

  .gnav__item {
    position: relative;
    margin-right: 14px;
    padding-right: 14px;

    @include media(pc-s) {
      margin-right: res(14px);
      padding-right: res(14px);
    }

    &:last-child {
      margin-right: 0;
      padding-right: 0;

      &::before {
        display: none;
      }
    }

    &::before {
      content: "";
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
      width: 1px;
      height: 20px;
      background-color: #666666;

      @include media(pc-s) {
        height: res(20px);
      }
    }

    & > a {
      position: relative;
      display: block;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: track(50);

      @include media(pc-s) {
        font-size: res(1.5rem);
      }

      &:hover {
        opacity: 0.7;
      }
    }

    &.--airport > a {
      padding-left: 20px;

      @include media(pc-s) {
        padding-left: res(20px);
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 16px;
        height: 16px;
        background-image: url(../images/common/icon/airport_01.svg);
        background-repeat: no-repeat;
        background-size: contain;

        @include media(pc-s) {
          width: res(16px);
          height: res(16px);
        }
      }
    }
  }

  .headerBox {
    @include media(tb) {
      display: none;
    }

    &__btn {
      width: 170px;
      margin-bottom: 8px;

      @include media(pc-s) {
        width: res(170px);
        margin-bottom: res(8px);
      }

      &:last-child {
        margin-bottom: 0;

        & > a {
          background-color: $color-main;
          color: $color-wh;

          &::before {
            border-top: solid 1px $color-wh;
            border-right: solid 1px $color-wh;
          }
        }
      }

      & > a {
        position: relative;
        display: block;
        background-color: $color-sub01;
        padding: 11px 10px;
        border-radius: 0.4em;
        font-size: 1.3rem;
        font-weight: 600;
        color: $color-main;
        text-align: center;

        @include media(pc-s) {
          padding: res(11px) res(10px);
          font-size: res(1.3rem);
        }

        &::before {
          @include arrow;
          right: 10px;
          width: 8px;
          height: 8px;
          border-top: solid 1px $color-main;
          border-right: solid 1px $color-main;

          @include media(pc-s) {
            right: 7px;
            width: 6px;
            height: 6px;
          }
        }

        &:hover {
          opacity: 0.7;
        }

        & > span {
          padding-right: 10px;

          @include media(pc-s) {
            padding-right: res(10px);
          }
        }
      }
    }
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
}
