.company {

  &__inner {
    padding: 0 117px;

    @include media(pc-s) {
      padding: 0 res(117px);
    }

    @include media(sp) {
      padding: 0;
    }
  }
}


.companyBox {
  display: flex;
  align-items: center;
  max-width: 765px;
  width: 100%;
  margin: 0 auto;
  padding: 34px 0;
  border-bottom: #999899 1px dotted;

  @include media(pc-s) {
    max-width: res(765px);
    padding: res(34px) 0;
  }

  @include media(sp) {
    flex-direction: column;
    align-items: normal;
    padding: 18px 0;
    padding-left: 10px !important;
  }

  &.-two {
    padding: 28px 0;

    @include media(pc-s) {
      padding: res(28px) 0;
    }

    @include media(sp) {
      padding: 20px 0 14px;
    }

    .companyBox__text {
      line-height: 1.444444444;

      @include media(sp) {
        margin-top: 8px;
        line-height: 1.6;
      }

    }
  }


  &.-sptow {
    // padding: 14px 0;

    .companyBox__text {
      @include media(sp) {
        margin-top: 8px;
        line-height: 1.6;
      }
    }
  }

  &:first-of-type {
    margin-top: 108px;
    padding-top: 0;

    @include media(pc-s) {
      margin-top: res(108px);
    }

    @include media(sp) {
      margin-top: 44px;
    }
  }

  &.-last {
    border-bottom: none;
  }


  &__title {
    width: 245px;
    padding-left: 8px;
    font-size: 1.6rem;
    color: #999899;
    font-weight: 500;
    letter-spacing: track(20);

    @include media(pc-s) {
      width: res(245px);
      padding-left: res(8px);
      font-size: res(1.6rem);
    }

    @include media(sp) {
      font-size: 1.4rem;
      padding-left: 0;
    }
  }

  &__text {
    font-size: 1.7rem;
    color: #000;
    font-weight: 400;
    letter-spacing: track(20);

    @include media(pc-s) {
      font-size: res(1.7rem);
    }

    @include media(sp) {
      margin-top: 12px;
      font-size: 1.5rem;
      letter-spacing: track(50);
    }
  }
}


.companyMap {
  position: relative;
  max-width: 1000px;
  width: 100%;
  margin: 0 auto;
  margin-top: 54px;

  @include media(pc-s) {
    max-width: res(1000px);
    margin-top: res(54px);
  }

  @include media(sp) {
    margin-top: 12px;
  }


  &__box {
    &::before {
      content: '';
      display: block;
      padding-top: percentage(600px / 1000px);

      @include media(sp) {
        padding-top: percentage(180px / 360px);
      }
    }

    &>iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

}
