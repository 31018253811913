// contactHead
.contactHead {
  margin-top: 110px;

  @include media(pc-s) {
    margin-top: res(110px);
  }

  @include media(sp) {
    margin-top: 50px;
  }

  &.-confirm {
    display: none;
  }

  &__txt {
    font-size: 1.5rem;
    letter-spacing: track(100);
    line-height: line(31, 15);
    text-align: center;

    @include media(pc-s) {
      font-size: res(1.5rem);
    }

    @include media(sp) {
      font-size: 1.3rem;
      letter-spacing: track(50);
    }

    .small {
      font-size: 1.3rem;
      color: #E71F19;

      @include media(pc-s) {
        font-size: res(1.3rem);
      }

      @include media(sp) {
        font-size: 1.2rem;
      }
    }
  }
}
