/* pagetop
------------------------------------------------ */
.pagetop {
  position: fixed;
  right: 1.8%;
  bottom: 2.6%;
  z-index: 2;
  display: none;

  @include media(tb) {
    right: 20px;
    bottom: 8%;
  }

  @include media(sp) {
    right: 15px;
    bottom: 12%;
  }

  &>a {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    background-color: $color-main;
    border-radius: 50%;
    border: 1px solid $color-wh;

    @include media(pc-s) {
      width: res(100px);
    }

    @include media(sp) {
      width: 60px;
    }

    &::before {
      content: '';
      display: block;
      padding-top: 100%;
    }

    img {
      width: 44px;
      height: 53px;
      margin-bottom: 8px;
      transition: transform .2s;

      @include media(pc-s) {
        width: res(44px);
        height: res(53px);
        margin-bottom: res(8px);
      }

      @include media(sp) {
        width: 19px;
        height: 26px;
        margin-bottom: 4px;
      }
    }

    &:hover {
      opacity: .8;

      @include media(tb) {
        opacity: 1;
      }
    }
  }
}
