/* #### Generated By: http://www.cufonfonts.com #### */

// @font-face {
//   font-family: "Hiragino Sans";
//   src: local(HiraginoSans-W3);
//   font-weight: 400;
// }

// @font-face {
//   font-family: "Hiragino Sans";
//   src: local(HiraginoSans-W4);
//   font-weight: 400;
// }

// @font-face {
//   font-family: "Hiragino Sans";
//   src: local(HiraginoSans-W5);
//   font-weight: 500;
// }

// @font-face {
//   font-family: "Hiragino Sans";
//   src: local(HiraginoSans-W6);
//   font-weight: 600;
// }

// @font-face {
//   font-family: "Hiragino Sans";
//   src: local(HiraginoSans-W7);
//   font-weight: 600;
// }

// @font-face {
//   font-family: "Hiragino Sans";
//   src: local(HiraginoSans-W8);
//   font-weight: 800;
// }

// @font-face {
//   font-family: "Hiragino Sans W9";
//   src: local(HiraginoSans-W9);
//   font-weight: 900;
// }
