/* TOP
------------------------------------------------- */

// topAbout
.topAbout {
  margin-top: 100px;
  text-align: center;

  @include media(pc-s) {
    margin-top: res(100px);
  }

  @include media(sp) {
    margin-top: 50px;
  }

  &__title {
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: track(150);
    line-height: line(46, 30);
    font-weight: 600;

    @include media(pc-s) {
      font-size: res(4.5rem);
    }

    @include media(sp) {
      font-size: 2.3rem;
      line-height: line(36, 23);
    }
  }

  &__text {
    margin-top: 40px;
    font-size: 1.9rem;
    font-weight: 400;
    letter-spacing: track(100);
    line-height: line(40, 19);

    @include media(pc-s) {
      margin-top: res(40px);
      font-size: res(1.9rem);
    }

    @include media(sp) {
      margin-top: 24px;
      font-size: 1.5rem;
      letter-spacing: track(50);
      line-height: line(31, 15);
    }

    &.-fr {
      margin-top: 26px;

      @include media(pc-s) {
        margin-top: 26px;
      }

      @include media(sp) {
      }
    }
  }
}

// topNews
.topNews {
  margin-top: 100px;

  @include media(pc-s) {
    margin-top: res(100px);
  }

  @include media(sp) {
    margin-top: 48px;
  }

  &__inner {
    background-color: $color-bg;
    padding: 52px 105px 55px;
    border-radius: 2em;

    @include media(pc-s) {
      padding: res(52px) res(105px) res(55px);
    }

    @include media(sp) {
      padding: 32px 20px 33px;
    }
  }

  &__title {
    font-size: 3.2rem;
    letter-spacing: track(300);
    font-weight: bold;
    text-align: center;

    @include media(pc-s) {
      font-size: res(3.2rem);
    }

    @include media(sp) {
      font-size: 2.4rem;
    }

    .en {
      display: block;
      margin-top: 12px;
      font-size: 1.2rem;
      font-family: $font-en;
      font-weight: 500;
      letter-spacing: track(200);
      color: #b2b2b2;

      @include media(pc-s) {
        margin-top: res(12px);
        font-size: res(1.2rem);
      }

      @include media(sp) {
        margin-top: 10px;
        font-size: 1.2rem;
      }
    }
  }

  &__list {
    margin-top: 13px;

    @include media(pc-s) {
      margin-top: res(13px);
    }

    @include media(sp) {
      margin-top: 6px;
    }
  }

  &__item {
    padding-left: 108px;
    border-bottom: 1px dotted #b2b2b2;

    @include media(pc-s) {
      padding-left: res(108px);
    }

    @include media(tb) {
      padding-left: 20px;
    }

    @include media(sp) {
      padding-left: 0;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 25px 0 14px;

      @include media(pc-s) {
        padding: res(25px) 0 res(14px);
      }

      @include media(sp) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 16px 0 10px;
      }

      &__time {
        margin-right: 35px;
        font-size: 1.4rem;
        font-weight: 400;
        letter-spacing: track(100);
        color: #7f8080;

        @include media(pc-s) {
          margin-right: res(35px);
          font-size: res(1.4rem);
        }

        @include media(sp) {
          margin-right: 0;
          font-size: 1.2rem;
        }
      }

      &__title {
        font-size: 1.6rem;
        font-weight: bold;
        letter-spacing: track(50);
        line-height: 1.4;

        @include media(pc-s) {
          font-size: res(1.6rem);
        }

        @include media(sp) {
          margin-top: 6px;
          font-size: 1.5rem;
        }
      }

      &:hover {
        opacity: 0.5;
      }
    }
  }

  &__btn {
    max-width: 200px;
    width: 100%;
    margin: 42px auto 0;

    @include media(pc-s) {
      max-width: res(200px);
      margin-top: res(42px);
    }

    @include media(sp) {
      max-width: 175px;
      margin-top: 22px;
    }

    & > a {
      position: relative;
      display: block;
      background-color: $color-wh;
      padding: 16px 0;
      border: 2px solid $color-sub02;
      border-radius: 10em;
      font-size: 1.5rem;
      font-weight: bold;
      letter-spacing: track(50);
      text-align: center;
      color: $color-sub02;

      @include media(pc-s) {
        padding: res(16px) 0;
        font-size: res(1.5rem);
      }

      @include media(sp) {
        padding: 16px 0;
        font-size: 1.5rem;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: 11%;
        content: "";
        transform: translateY(-50%) rotate(45deg);
        display: block;
        width: 9px;
        height: 9px;
        border-top: solid 1px $color-sub02;
        border-right: solid 1px $color-sub02;

        @include media(pc-s) {
          width: res(9px);
          height: res(9px);
        }

        @include media(sp) {
          right: 13%;
          width: 9px;
          height: 9px;
        }
      }

      .text {
        padding-right: 10px;

        @include media(pc-s) {
          padding-left: res(10px);
        }

        @include media(sp) {
          padding-right: 22px;
        }
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }
}

// topSales
.topSales {
  margin-top: 90px;

  @include media(pc-s) {
    margin-top: res(90px);
  }

  @include media(sp) {
  }

  & > div {
    height: 215px;
    background-color: #666;
  }
}

// topMenu
.topMenu {
  @include re-m;
  margin-top: 90px;

  @include media(pc-s) {
    margin-top: res(90px);
  }

  @include media(sp) {
    margin-top: 58px;
  }

  &__inner {
    max-width: 1100px;
    width: 100%;
    margin: 0 auto;

    @include media(pc-s) {
      max-width: res(1100px);
    }

    @include media(tb) {
      padding: 0 20px;
    }

    @include media(sp) {
      padding: 0 30px;
    }
  }

  //2021.02.19 omi 追加

  &__title {
    font-size: 4.5rem;
    font-weight: bold;
    letter-spacing: track(150);
    font-weight: 600;
    text-align: center;

    @include media(pc-s) {
      font-size: res(4.5rem);
    }

    @include media(sp) {
      font-size: 2.8rem;
    }
  }

  &__list {
    display: flex;
    margin-left: -77px;
    margin-top: 46px;

    @include media(pc-s) {
      margin-left: res(-77px);
      margin-top: res(46px);
    }

    @include media(tb) {
      margin-left: -30px;
    }

    @include media(sp) {
      flex-direction: column;
      align-items: center;
      margin-left: 0;
      margin-top: 22px;
    }
  }

  &__item {
    width: calc(33.3333% - 77px);
    margin-left: 77px;
    transition: opacity 0.2s;

    @include media(pc-s) {
      width: calc(33.3333% - #{res(77px)});
      margin-left: res(77px);
    }

    @include media(tb) {
      width: calc(33.3333% - 30px);
      margin-left: 30px;
    }

    @include media(sp) {
      max-width: 400px;
      width: 100%;
      margin-top: 32px;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    .card {
      display: block;

      &:hover {
        opacity: 0.7;

        @include media(tb) {
          opacity: 1;
        }
      }

      &__img {
        position: relative;
        width: 100%;
        background-image: url(../images/top/topMenu_img_01.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 2em;

        &::before {
          content: "";
          display: block;
          padding-top: 100%;
        }
      }

      &__title {
        position: absolute;
        bottom: 9.5%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        font-size: 3.2rem;
        font-weight: bold;
        letter-spacing: track(130);
        line-height: line(41, 32);
        text-align: center;
        color: $color-wh;
        text-shadow:
          0px 0px 4px #231815,
          0px 0px 20px #231815;

        @include media(pc-s) {
          font-size: res(3.2rem);
        }

        @include media(tb) {
          font-size: 2.2rem;
        }

        @include media(sp) {
          font-size: 3rem;
        }
      }

      &__text {
        margin-top: 16px;
        font-size: 1.6rem;
        font-weight: 500;
        line-height: line(25, 16);
        text-align: center;

        @include media(pc-s) {
          margin-top: res(16px);
          font-size: res(1.6rem);
        }

        @include media(sp) {
          margin-top: 12px;
          font-size: 1.6rem;
        }
      }
    }

    &:nth-child(2) {
      .card {
        &__img {
          background-image: url(../images/top/topMenu_img_02.png);
        }
      }
    }

    &:last-child {
      .card {
        &__img {
          background-image: url(../images/top/topMenu_img_03.png);
        }
      }
    }
  }

  &__btn {
    width: 100%;
    max-width: 477px;
    margin: 42px auto 0;

    @include media(pc-s) {
      max-width: res(477px);
      margin-top: res(42px);
    }

    @include media(sp) {
      max-width: 400px;
      margin-top: 28px;
    }

    & > a {
      position: relative;
      display: block;
      background-color: $color-wh;
      padding: 32px 0;
      border: 3px solid $color-main;
      border-radius: 0.5em;
      font-size: 2.4rem;
      font-weight: 500;
      letter-spacing: track(100);
      color: $color-main;
      text-align: center;

      @include media(pc-s) {
        padding: res(32px) 0;
        font-size: res(2.4rem);
      }

      @include media(sp) {
        padding: 23px 0;
        border-width: 2px;
        font-size: 1.9rem;
      }

      &::before {
        @include arrow;
        right: 5.5%;
        width: 14px;
        height: 14px;
        border-top: solid 1px $color-main;
        border-right: solid 1px $color-main;
      }

      &:hover {
        opacity: 0.7;

        @include media(tb) {
          opacity: 1;
        }
      }
    }
  }
}

// topContact
.topContact {
  margin-top: 86px;

  @include media(pc-s) {
    margin-top: res(86px);
  }

  @include media(sp) {
    margin-top: 50px;
  }

  &__title {
    padding: 16px 0;
    border-top: 1px solid $color-bk;
    border-bottom: 1px solid $color-bk;
    font-size: 2.6rem;
    font-weight: 500;
    letter-spacing: track(50);
    text-align: center;

    @include media(pc-s) {
      padding: res(16px) 0;
      font-size: res(2.6rem);
    }

    @include media(sp) {
      padding: 12px 0;
      font-size: 1.8rem;
      line-height: line(25, 18);
    }
  }

  &__list {
    display: flex;
    margin-top: 42px;
    margin-left: -46px;

    @include media(pc-s) {
      margin-top: res(42px);
      margin-left: res(-46px);
    }

    @include media(sp) {
      flex-direction: column;
      align-items: center;
      margin-top: 22px;
      margin-left: 0;
    }
  }

  &__item {
    width: calc(50% - 46px);
    margin-left: 46px;

    @include media(pc-s) {
      width: calc(50% - #{res(46px)});
      margin-left: res(46px);
    }

    @include media(sp) {
      max-width: 400px;
      width: 100%;
      margin-top: 16px;
      margin-left: 0;

      &:first-child {
        margin-top: 0;
      }
    }

    & > a {
      position: relative;
      display: block;
      background-color: #fbd06f;
      padding: 32px 0;
      border-radius: 0.5em;
      font-size: 2.4rem;
      font-weight: 500;
      letter-spacing: track(100);
      color: $color-main;
      text-align: center;

      @include media(pc-s) {
        padding: res(32px) 0;
        font-size: res(2.4rem);
      }

      @include media(sp) {
        padding: 23px 0;
        font-size: 1.9rem;
      }

      &::before {
        @include arrow;
        right: 5.5%;
        width: 14px;
        height: 14px;
        border-top: solid 1px $color-main;
        border-right: solid 1px $color-main;
      }

      &:hover {
        opacity: 0.7;

        @include media(tb) {
          opacity: 1;
        }
      }
    }

    &:nth-child(2) {
      & > a {
        background-color: $color-main;
        color: $color-wh;

        &::before {
          border-top: solid 1px $color-wh;
          border-right: solid 1px $color-wh;
        }
      }
    }
  }

  &__tel {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 32px;

    @include media(pc-s) {
      margin-top: res(32px);
    }

    @include media(sp) {
      flex-direction: column;
      justify-content: flex-start;
      margin-top: 23px;
    }
  }

  &__num {
    font-size: 3rem;
    font-weight: 600;
    font-family: $font-en;
    letter-spacing: track(10);

    @include media(pc-s) {
      font-size: res(3rem);
    }

    @include media(sp) {
      font-size: 2.5rem;
    }

    & > a {
      display: block;
    }
  }

  &__txt {
    margin-left: 10px;
    font-size: 1.4rem;
    font-weight: 500;

    @include media(pc-s) {
      margin-left: res(10px);
      font-size: res(1.4rem);
    }

    @include media(sp) {
      margin-top: 8px;
      margin-left: 0;
      font-size: 1.4rem;
    }
  }
}

// topNote
.topNote {
  margin-top: 90px;

  @include media(pc-s) {
    margin-top: res(90px);
  }

  @include media(sp) {
    margin-top: 54px;
  }

  &__img {
    position: relative;
    width: 100%;

    @include media(sp) {
      max-width: 440px;
      margin: 0 auto;
    }

    &::before {
      content: "";
      display: block;
      padding-top: percentage(218px / 1000px);

      @include media(sp) {
        padding-top: percentage(364px /320px);
      }
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

/* スマホ横
------------------------------------------------ */
@media only screen and (orientation: landscape) and (max-width: 813px) {
}
