// 確認画面用
.mw_wp_form_confirm {

  .form {

    &__block {
      margin-top: 60px;
      padding-top: 0;
      border-top: none;

      @include media(pc-s) {
        margin-top: res(60px);
      }

      @include media(sp) {
        margin-top: 18px;
      }

      &:first-child {
        margin-top: 0;
        border-top: none;
      }
    }

    &__inner {
      padding: 0 180px;

      @include media(pc-s) {
        padding: 0 res(180px);
      }

      @include media(tb) {
        padding: 0 80px;
      }

      @include media(sp) {
        padding: 0;
      }
    }

    &__container {
      padding: 20px 0;
      border-bottom: none;

      @include media(pc-s) {
        padding: res(20px) 0;
      }

      @include media(sp) {
        padding: 14px 16px;
      }

      &:first-of-type {

        @include media(sp) {
          margin-top: 10px;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }

      &.-borderT {
        margin-top: 0;
        border-top: none;
      }

      &.-flexstart {
        align-items: flex-start;
      }

      &.-plan {
        align-items: center;

        @include media(sp) {
          align-items: flex-start;
        }
      }
    }

    &__title {
      width: 33%;
      font-size: 1.5rem;

      @include media(pc-s) {
        font-size: res(1.5rem);
      }

      @include media(tb) {
        font-size: 1.3rem;
      }

      @include media(sp) {
        width: 100%;
      }

      .red {
        display: none;
      }
    }

    &__box {
      width: 67%;

      @include media(sp) {
        width: 100%;
      }

      &.-name {
        @include media(sp) {
          margin-top: 12px;
        }
      }

      .input {
        font-size: 1.6rem;

        @include media(pc-s) {
          font-size: res(1.6rem);
        }

        @include media(tb) {
          font-size: 1.4rem;
        }

        &>p {
          @include media(sp) {
            display: none;
          }
        }
      }

      // selectタグ
      .select {

        &__item {
          font-size: 1.6rem;

          @include media(pc-s) {
            font-size: res(1.6rem);
          }

          @include media(tb) {
            font-size: 1.4rem;
          }

          &>p {
            font-size: 1.6rem;

            @include media(pc-s) {
              font-size: res(1.6rem);
            }

            @include media(tb) {
              font-size: 1.4rem;
            }
          }
        }
      }

      // radioタグ
      .radio {

        &__item {
          margin-top: 0;
          font-size: 1.6rem;

          @include media(pc-s) {
            font-size: res(1.6rem);
          }

          @include media(tb) {
            font-size: 1.4rem;
          }
        }

        .radioArea {
          margin-top: 23px;

          @include media(pc-s) {
            margin-top: res(23px);
          }

          @include media(sp) {
            width: 100%;
            margin-top: 12px;
          }

          &__text {
            display: none;
          }

          &__input {
            margin-top: 0;
            font-size: 1.6rem;

            @include media(pc-s) {
              font-size: res(1.6rem);
            }

            @include media(tb) {
              font-size: 1.4rem;
            }
          }
        }

        // 回答方法用CSS
        &.-method {

          .radio__item {
            margin-left: 0;
          }
        }
      }

      // textarea
      .textarea {
        font-size: 1.6rem;
        line-height: 1.5;

        @include media(pc-s) {
          font-size: res(1.6rem);
        }

        @include media(tb) {
          font-size: 1.4rem;
        }
      }
    }


    // お問い合わせ用CSS
    &__list {
      margin-top: 58px;
      padding: 0;
      border-bottom: none;

      @include media(pc-s) {
        margin-top: res(58px);
        padding: 0;
      }

      @include media(sp) {
        margin-top: 22px;
        padding: 0 16px;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &__item {
      margin-top: 26px;

      @include media(pc-s) {
        margin-top: res(26px);
      }

      @include media(sp) {
        margin-top: 28px;
      }

      &.-flexstart {
        align-items: flex-start;
      }

      // input(name)
      .name {
        @include media(sp) {
          flex-direction: row;
        }

        &__item {
          font-size: 1.6rem;

          @include media(pc-s) {
            font-size: res(1.6rem);
          }

          @include media(sp) {
            width: auto;
            font-size: 1.4rem;
          }

          &:last-child {
            margin-left: 1em;

            @include media(sp) {
              margin-top: 0;
            }
          }

          p {
            display: none;
          }
        }
      }

      // input(tel)
      .tel {
        margin-left: 0;

        &__item {
          width: auto;
          margin-left: 0;
          font-size: 1.6rem;

          @include media(pc-s) {
            font-size: res(1.6rem);
          }

          @include media(sp) {
            font-size: 1.4rem;
          }

          &::before {
            display: none;
          }
        }
      }
    }

    // 備考用
    .formArea {
      padding: 0;

      @include media(pc-s) {
        padding: 0;
      }

      @include media(sp) {
        padding: 0 16px 14px;
      }

      &__txt {
        display: none;
      }

      &__container {
        display: flex;
        margin-top: 33px;

        @include media(pc-s) {
          margin-top: res(33px);
        }

        @include media(sp) {
          flex-direction: column;
          margin-top: 28px;
        }
      }
    }
  }


  // submitBtn
  .formBtn {
    // margin-top: 90px;

    // @include media(pc-s) {
    //   margin-top: res(90px);
    // }

    // @include media(sp) {
    //   margin-top: 45px;
    // }

    &__btn {
      max-width: 215px;

      @include media(pc-s) {
        max-width: res(215px);
      }

      @include media(tb) {
        max-width: 240px;
      }

      @include media(sp) {
        max-width: 200px;
        width: 50%
      }

      &::before {
        right: 9%;

        @include media(sp) {
          right: 10%;
          width: 10px;
          height: 10px;
        }
      }

      .submitBtn {
        padding: 22px 0;
        font-size: 1.9rem;

        @include media(pc-s) {
          padding: res(22px) 0;
          font-size: res(1.9rem);
        }

        @include media(sp) {
          padding: 20px 0;
          font-size: 1.6rem;
        }
      }


      // 修正するボタン
      &.-backBtn {
        display: block !important;
        margin-right: 30px;

        @include media(pc-s) {
          margin-right: res(30px);
        }

        @include media(sp) {
          margin-right: 10px;
        }

        &::before {
          right: auto;
          left: 9%;
          border-color: $color-main;
          transform: translateY(-50%) rotate(-135deg);

          @include media(sp) {
            left: 10%;
          }
        }

        .submitBtn {
          background-color: $color-wh;
          color: $color-main;
        }
      }
    }
  }
}
